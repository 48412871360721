import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, Anchor, Text, Box, TextInput, TextArea, MaskedInput } from 'grommet';
import { FormClose, Share } from 'grommet-icons';

import { ErrorForm } from '../../../../components';
import { TitleField, DescriptionField } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import { create, hideFormManagerLayer } from '../../actions/form-manager';
import { changeValues } from '../../../../actions/form';

import visibleWidth from '../../../../constants/layer-width';
import urlsApp from '../../../../config/url-routes';

const FormCreate = (props) => {

  const handlePost = useCallback(formParams => {
    const { create } = props;
    create({ formParams });
  }, [props]);

  const enabledButton = useCallback(() => {
    const {
      displayName,
      displayEmail,
      displayPhoneNumber,
      evidence
    } = props;

    return displayName && displayEmail && displayPhoneNumber && evidence;
  }, [props]);


  const {
    displayName,
    displayEmail,
    displayPhoneNumber,
    evidence,

    toggleLayer,
    loadingPostForm,
    postDataError,

    changeValues,
    hideFormManagerLayer,
  } = props;


  if(!toggleLayer || toggleLayer !== 'manager')
    return null;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Layer
          responsive={false}
          full='vertical'
          position='left'
          onClickOutside={hideFormManagerLayer}
          onEsc={hideFormManagerLayer}
        >
          <Box
            fill='vertical'
            width={visibleWidth[size]}
            pad={{ top: 'large', bottom: 'xsmall' }}
            overflow='auto'
            flex
          >
            <>
              <Box
                direction='row'
                justify='between'
                align='baseline'
                margin={{ horizontal: 'large' }}
              >
                <TitleDetail value='Configurar perfil de exibição' />

                <Button
                  plain
                  title='Fechar'
                  icon={<FormClose />}
                  onClick={hideFormManagerLayer}
                />
              </Box>


              <Box flex overflow='auto'>
                {/* Sobre */}
                <Box
                  gap={ size === 'small' ? 'medium' : 'small' }
                  margin={{
                    vertical: 'small',
                    horizontal: 'large'
                  }}
                  flex={{ shrink: 0 }}
                >
                  <Text color='dark-2' size='small'>
                    Confira as <Anchor href={urlsApp.profileGuideline} target='_blank'>diretrizes de nomenclatura <Share color='brand' size='small' /></Anchor> para configurar o perfil de exibição.
                    Os dados serão analisados antes de serem aprovados.
                  </Text>
                </Box>


                {/* Nome */}
                <Box
                  margin={{
                    vertical: 'small',
                    horizontal: 'large'
                  }}
                  direction={ size === 'small' ? 'column' : 'row' }
                  align='stretch'
                  flex={{ shrink: 0 }}
                  gap='small'
                >
                  <Box
                    width={ size === 'small' ? '100vw' : '50vw'}
                    gap='xxsmall'
                  >
                    <TitleField text='Nome de exibição' required />

                    <DescriptionField
                      text='O nome será utilizado para identificar você.
                            Será utilizado em funcionalidades que envolve a comunicação com seus cadastros.'
                    />
                  </Box>

                  <Box
                    width={ size === 'small' ? '100vw' : '50vw'}
                  >
                    <TextInput
                      maxLength={50}
                      value={displayName ?? ''}
                      placeholder='Ex.: Vereador Carlos, Vereadora Marta, etc.'
                      onChange={event =>
                        changeValues({ fieldName: 'displayName', value: event.target.value }, 'formChangeValues')
                      }
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='displayName' />
                  </Box>
                </Box>


                <Box
                  border={{ color: 'light-3', side: 'bottom' }}
                  margin={{ top: 'medium', bottom: 'medium' }}
                />

                {/* Telefone */}
                <Box
                  margin={{
                    vertical: 'small',
                    horizontal: 'large'
                  }}
                  direction={ size === 'small' ? 'column' : 'row' }
                  align='stretch'
                  flex={{ shrink: 0 }}
                  gap='small'
                >
                  <Box
                    width={ size === 'small' ? '100vw' : '50vw'}
                    gap='xxsmall'
                  >
                    <TitleField text='Telefone' required />

                    <DescriptionField
                      text='Deve ser um telefone ou celular que você acessa com frequência.'
                    />
                  </Box>

                  <Box
                    width={ size === 'small' ? '100vw' : '50vw'}
                  >
                    <MaskedInput
                      value={displayPhoneNumber ?? ''}
                      placeholder='Telefone ou celular...'
                      onChange={event =>
                        changeValues({ fieldName: 'displayPhoneNumber', value: event.target.value }, 'formChangeValues')
                      }
                      mask={[
                        { fixed: '(' },
                        { length: 2, regexp: /\d/ },
                        { fixed: ')' },
                        { fixed: ' ' },
                        { length: 4, regexp: /\d/ },
                        { fixed: '-' },
                        { length: [4, 5], regexp: /\d/ }
                      ]}
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='displayPhoneNumber' />
                  </Box>
                </Box>


                {/* E-mail */}
                <Box
                  margin={{
                    vertical: 'small',
                    horizontal: 'large'
                  }}
                  direction={ size === 'small' ? 'column' : 'row' }
                  align='stretch'
                  flex={{ shrink: 0 }}
                  gap='small'
                >
                  <Box
                    width={ size === 'small' ? '100vw' : '50vw'}
                    gap='xxsmall'
                  >
                    <TitleField text='E-mail' required />

                    <DescriptionField
                      text='Deve ser um e-mail que você acessa com frequência.'
                    />
                  </Box>

                  <Box
                    width={ size === 'small' ? '100vw' : '50vw'}
                  >
                    <TextInput
                      maxLength={50}
                      value={displayEmail ?? ''}
                      placeholder='nome@dominio.com.br'
                      onChange={event =>
                        changeValues({ fieldName: 'displayEmail', value: event.target.value }, 'formChangeValues')
                      }
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='displayEmail' />
                  </Box>
                </Box>


                <Box
                  border={{ color: 'light-3', side: 'bottom' }}
                  margin={{ top: 'medium', bottom: 'medium' }}
                />

                {/* Evidencia */}
                <Box
                  margin={{
                    vertical: 'small',
                    horizontal: 'large'
                  }}
                  flex={{ shrink: 0 }}
                >
                  <TitleField text='Informações adicionais' required />

                  <TextArea
                    rows={3}
                    resize='vertical'
                    maxLength={200}
                    value={evidence ?? ''}
                    placeholder='Informe como podemos comprovar que esses dados são reais.
                                 Pode ser site da Câmara, link das redes sociais, site próprio.'
                    onChange={event =>
                      changeValues({ fieldName: 'evidence', value: event.target.value }, 'formChangeValues')
                    }
                  />

                  <ErrorForm errorsList={postDataError.errors} fieldName='evidence' />
                </Box>
              </Box>


              <Box
                direction='row'
                pad={{ vertical: 'medium' }}
                justify='center'
                align='center'
                gap='small'
              >
                <Box width='xsmall'>
                  <Anchor
                    color='dark-3'
                    label='Cancelar'
                    disabled={ loadingPostForm }
                    onClick={ hideFormManagerLayer }
                  />
                </Box>

                <Box width='small'>
                  <Button
                    color='brand'
                    primary
                    label={ !loadingPostForm ? 'Salvar' : 'Salvando...' }
                    disabled={ loadingPostForm || !enabledButton() }
                    onClick={() =>
                      handlePost({
                        displayName,
                        displayEmail,
                        displayPhoneNumber,
                        evidence,
                      })
                    }
                  />
                </Box>


              </Box>
            </>
          </Box>
        </Layer>
      )}
    </ResponsiveContext.Consumer>
  );
}

const mapStateToProps = ({ layerReducer, formCreateUpdateCopyGeneric }) => ({
  displayName: formCreateUpdateCopyGeneric.displayName,
  displayEmail: formCreateUpdateCopyGeneric.displayEmail,
  displayPhoneNumber: formCreateUpdateCopyGeneric.displayPhoneNumber,
  evidence: formCreateUpdateCopyGeneric.evidence,

  loadingPostForm: formCreateUpdateCopyGeneric.loadingPostForm,
  postDataError: formCreateUpdateCopyGeneric.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    create,
    changeValues,
    hideFormManagerLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormCreate);

import TYPES from './types';
import { queryCommon, queryOccupations, queryLegislativeProcessAuthors } from '../api/queries';
import { listVoter } from '../api/voter';

export const getOfficeOptions = () => {
  return dispatch => {
    queryCommon('officeList')
      .then(({ data }) => {
        dispatch({
          type: TYPES.getOfficeOptions,
          payload: data.map(result => ({
            id: result.id,
            name: result.name,
          })),
        })
      })
  }
};

export const getStatusServiceOptions = () => {
  return dispatch => {
    queryCommon('treatmentStatusList')
      .then(response =>
        dispatch({
          type: TYPES.getStatusServiceOptions,
          payload: response.data.map(result => ({
            id: result.treatmentStatusId,
            name: result.statusName,
          })),
        })
      )
  }
}

export const getOriginsServiceOptions = () => {
  return dispatch => {
    queryCommon('treatmentOriginList')
      .then(response => {
        dispatch({
          type: TYPES.getOriginServiceOptions,
          payload: response.data.map(result => ({
            id: result.id,
            name: result.origin,
          })),
        })
      })
  }
}

export const getCategoriesServiceOptions = () => {
  return dispatch => {
    queryCommon('treatmentCategoryList')
      .then(response => {
        dispatch({
          type: TYPES.getCategoryServiceOptions,
          payload: response.data.map(result => ({
            id: result.treatmentCategoryId,
            name: result.category,
          })),
        })
      })
  }
}

export const getUsersOptions = () => {
  return dispatch => {
    queryCommon('userList')
      .then(response => {
        dispatch({
          type: TYPES.getUsersOptions,
          payload: response.data.map(result => ({
            id: result.id,
            name: result.name,
            status: result.status,
            phone: result.phone,
          })),
        })
      })
  }
}

export const getGroupsOptions = () => {
  return dispatch => {
    queryCommon('electorGroupList')
      .then(response => {
        dispatch({
          type: TYPES.getGroupsOptions,
          payload: response.data.map(result => ({
            id: result.id,
            name: result.groupName,
          })),
        })
      })
  }
}

export const getVotersOptions = params => {
  return dispatch => {
    listVoter(params)
      .then(response => {

        dispatch({
          type: TYPES.getVotersOptions,
          payload: response.data.data.map(result => {
            const {
              id,
              name,
              telephone,
              districtName,
              cityName
            } = result;

            return {
              id,
              name,
              telephone,
              districtName,
              cityName,
            }
          }),
        })
      })
  }
}

export const getDocumentTypeOptions = () => {
  return dispatch => {
    queryCommon('documentTypeList')
      .then(response =>
        dispatch({
          type: TYPES.getDocumentTypeOptions,
          payload: response.data.map(result => ({
            id: result.id,
            name: result.document,
          })),
        })
      )
  }
}

export const getAuthorsOptions = params => {
  return dispatch => {
    queryLegislativeProcessAuthors(params)
      .then(response => {

        dispatch({
          type: TYPES.getAuthorsOptions,
          payload: response.data.data.map(result => {
            const { name } = result;

            return {
              name,
            }
          }),
        })
      })
  }
}

export const getStatusLegislativeProcess = () => {
  return dispatch => {
    queryCommon('legislativeProcesseStatusList')
      .then(response =>
        dispatch({
          type: TYPES.getStatusLegislativeProcessOptions,
          payload: response.data.map(result => ({
            id: result.id,
            name: result.statusName,
          })),
        })
      )
  }
}

export const getDepartments = () => {
  return dispatch => {
    queryCommon('departmentList')
      .then(response =>
        dispatch({
          type: TYPES.getdepartmentOptions,
          payload: response.data.map(({ id, name, clientId }) => ({
            id,
            name,
            clientId,
          })),
        })
      )
  }
}

export const getIntegrationUserOptions = () => {
  return dispatch => {
    queryCommon('integrationCalendarList')
      .then(response =>
        dispatch({
          type: TYPES.getIntegrationUserOptions,
          payload: response.data.map(result => ({
            id: result.id,
            userId: result.userId,
            isValid: result.isValid,
          })),
        })
      )
  }
}

export const getOccupationOptions = params => {
  return dispatch => {
    queryOccupations(params)
      .then(response =>
        dispatch({
          type: TYPES.getOccupationOptions,
          payload: response.data.map(({ id, occupation }) => ({
            id,
            name: occupation,
          })),
        })
      )
  }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Details } from '../components/details/details';
import { Search } from '../components/form-search/form-search';
import { Delete } from '../components/form-delete/form-delete';
import { ChangeStatus } from '../components/form-change-status/form-change-status';
import { Comment } from '../components/form-comment/form-comment';
import { Create } from '../components/form-manager/form-manager';
import { Reminder } from '../components/form-reminder/form-reminder';
import { SearchResult } from '../components/search-result/search-result';
import { Communication } from '../components/communication/communication';
import { CreateArchive } from '../components/form-archive/form-archive';

import Header from '../../../components/header/header';

import { searchServices, setDefaultPageRules } from '../actions/form-search';

import {
  getUsersOptions,
  getDocumentTypeOptions,
  getGroupsOptions,
  getOfficeOptions,
  getStatusServiceOptions,
  getOriginsServiceOptions,
  getCategoriesServiceOptions,
  getDepartments,
} from '../../../actions/select-options';

import { formattedDateTime } from '../../../utils';

import paginationDefault from '../../../constants/pagination';

class Services extends Component {
  componentDidMount() {
    const {
      getGroupsOptions,
      getDocumentTypeOptions,
      getStatusServiceOptions,
      getOriginsServiceOptions,
      getCategoriesServiceOptions,
      getOfficeOptions,
      getUsersOptions,
      getDepartments,

      searchServices,
      setDefaultPageRules,

      reference,
      electorReference,
      electorName,
      groupId,
      statusId,
      originId,
      categoryId,
      officeId,
      responsibleId,
      description,
      startCreatedDate,
      endCreatedDate,
      documentId,
      documentYear,
      documentNumber,
      districtIds,
      departmentId,
    } = this.props;

    getGroupsOptions();
    getDocumentTypeOptions();
    getStatusServiceOptions();
    getOriginsServiceOptions();
    getCategoriesServiceOptions();
    getOfficeOptions();
    getUsersOptions();
    getDepartments();

    setDefaultPageRules();

    searchServices({
      reference,
      electorReference,
      electorName,
      groupId,
      statusId,
      originId,
      categoryId,
      officeId,
      responsibleId,
      description,
      startCreatedDate: formattedDateTime(startCreatedDate),
      endCreatedDate: formattedDateTime(endCreatedDate),
      documentId,
      documentYear,
      documentNumber,
      districtIds,
      departmentId,
      page: paginationDefault.page,
      limit: paginationDefault.limit,
    });
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <SearchResult />

        <Search />
        <Delete />
        <ChangeStatus />
        <Comment />
        <Create />
        <Reminder />
        <Details />
        <CreateArchive />
        <Communication />
      </>
    )
  }
}

const mapStateToProps = ({ servicesReducer: { formSearch }, authReducer: { authenticated } }) => ({
  userLogged: authenticated,

  reference: formSearch.reference,
  electorReference: formSearch.electorReference,
  electorName: formSearch.electorName,
  groupId: formSearch.groupId,
  statusId: formSearch.statusId,
  originId: formSearch.originId,
  categoryId: formSearch.categoryId,
  officeId: formSearch.officeId,
  responsibleId: formSearch.responsibleId,
  description: formSearch.description,
  startCreatedDate: formSearch.startCreatedDate,
  endCreatedDate: formSearch.endCreatedDate,
  documentId: formSearch.documentId,
  documentYear: formSearch.documentYear,
  documentNumber: formSearch.documentNumber,
  districtIds: formSearch.districtIds,
  departmentId: formSearch.departmentId,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    getGroupsOptions,
    getDocumentTypeOptions,
    getStatusServiceOptions,
    getOriginsServiceOptions,
    getCategoriesServiceOptions,
    getOfficeOptions,
    getUsersOptions,
    getDepartments,

    searchServices,
    setDefaultPageRules,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);

import { combineReducers } from 'redux';
import onboardingReducer from '../../modules/onboarding/reducers';
import authReducer from '../../modules/auth/reducers';
import invoiceReducer from '../../modules/invoice/reducers';
import appointmentsReducer from '../../modules/appointments/reducers';
import phonecallsReducer from '../../modules/phonecall/reducers';
import campaignsReducer from '../../modules/campaigns/reducers';
import servicesReducer from '../../modules/service/reducers';
import votersReducer from '../../modules/voter/reducers';
import legislativeProcessesReducer from '../../modules/legislative-process/reducers';
import customerAreaReducer from '../../modules/customer-area/reducers';
import settingsOriginReducer from '../../modules/settings-origin/reducers';
import settingsDepartmentReducer from '../../modules/settings-department/reducers';
import settingsGroupReducer from '../../modules/settings-group/reducers';
import settingsCategoryReducer from '../../modules/settings-category/reducers';
import settingsServiceStatusReducer from '../../modules/settings-service-status/reducers';
import settingsOfficeReducer from '../../modules/settings-office/reducers';
import settingsUserReducer from '../../modules/settings-user/reducers';
import dashboardSystemReducer from '../../modules/dashboard/reducers';
import taskReducer from '../../modules/task/reducers';
import expenseReducer from '../../modules/expense/reducers';
import surveyReducer from '../../modules/survey/reducers';
import manifestationReducer from '../../modules/manifestation/reducers';
import settingsLegislativeProcesseStatusReducer from '../../modules/settings-legislative-process-status/reducers';
import settingsIntegrationCalendarReducer from '../../modules/settings-integration-calendar/reducers';
import settingsProfileReducer from '../../modules/settings-profile/reducers';

import selectOptionsReducer from '../../reducers/select-options';
import formCreateUpdateCopyGeneric from '../../reducers/form';
import layerDetailsReducer from '../../reducers/layer-details';
import layerReducer from '../../reducers/layers';
import notificationReducer from '../../reducers/notification';

const rootReducers = combineReducers({
  onboardingReducer,
  authReducer,
  invoiceReducer,
  appointmentsReducer,
  phonecallsReducer,
  campaignsReducer,
  servicesReducer,
  votersReducer,
  legislativeProcessesReducer,
  customerAreaReducer,
  settingsOriginReducer,
  settingsDepartmentReducer,
  settingsGroupReducer,
  settingsCategoryReducer,
  settingsServiceStatusReducer,
  settingsOfficeReducer,
  settingsUserReducer,
  dashboardSystemReducer,
  taskReducer,
  expenseReducer,
  surveyReducer,
  manifestationReducer,
  settingsLegislativeProcesseStatusReducer,
  settingsIntegrationCalendarReducer,
  settingsProfileReducer,

  selectOptionsReducer,
  formCreateUpdateCopyGeneric,
  layerDetailsReducer,
  layerReducer,
  notificationReducer,
});

export default rootReducers;

import TYPES from '../actions/types';
import { insertRow } from '../utils';

const INITIAL_STATE = {
  loadingFormSearch: false,
  dataRows: [],
  getListError: false,
};

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.searchSuccess:
      return {
        ...state,
        dataRows: action.payload.data.data,
        getListError: false,
      };

    case TYPES.searchError:
      return {
        ...state,
        getListError: true,
        loadingFormSearch: !state.loadingFormSearch,
      }

    case TYPES.loadingFormSearch:
      return {
        ...state,
        getListError: false,
        loadingFormSearch: !state.loadingFormSearch,
      };

    case TYPES.insertItemList:
      return {
        ...state,
        dataRows: insertRow({
          object: action.payload,
          items: state.dataRows,
        }),
      }

    default:
      return state;
  }
};

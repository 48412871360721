import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, TextInput, Anchor, MaskedInput, Select, CheckBox, Button } from 'grommet';
import { Spinning } from 'grommet-controls';

import { TitleField } from '../../../../components/form';
import { ErrorForm } from '../../../../components';

import { searchCityOptions, searchDistrictOptions, searchAddressesOptions } from '../../actions/select-options';
import { changeField, changeStateAndCity, setAddressAfterSearch, clearAllAddress } from '../../actions/form-create';

function Address(props) {
  const [searchBy, setSearchBy] = useState('CEP');
  const [searchMethod, setSearchMethod] = useState('searchByZipcode');
  const [searchText, setSearchText] = useState('');
  const [searchStarted, setSearchStarted] = useState(false);
  const [manualAddress, setManualAddress] = useState(false);

  const onSearch = ({ method, text }) => {
    const { searchAddressesOptions } = props;
    searchAddressesOptions({ method, text });
  }

  const onSearchCities = text => {
    if (text.length < 2) return;

    const { searchCityOptions } = props;
    searchCityOptions({ name: text });
  };

  const onSearchDistricts = text => {
    const {cityId, searchDistrictOptions } = props;

    if (text.length < 1 || !cityId) return;
    searchDistrictOptions({ cityId, name: text })
  };

  const {
    cityOptions,
    districtOptions,
    addressOptions,
    cityOptionsLoad,
    districtOptionsLoad,
    addressOptionsLoad,

    address,
    addressNumber,
    additionalAddress,
    districtId,
    cityId,
    zipcode,
    district,
    city,
    state,
    withLocation,
    postDataError,

    changeField,
    changeStateAndCity,
    setAddressAfterSearch,
    clearAllAddress,
  } = props;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box margin={{ bottom: 'medium' }} gap='xsmall'>
            <Text size='large'>Endereço</Text>
          </Box>

          <Box
            direction={
              size !== 'small' && size !== 'medium' ? 'row' : 'column-reverse'
            }
            gap='small'
          >
            {/* coluna 1 - address */}
            <Box
              margin={{ right: 'large' }}
              width={size !== 'small' && size !== 'medium' ? '50vw' : '100vw'}
              gap='small'
            >
              {!manualAddress && (
                <>
                  {/* search address by street or zipcode */}
                  {!address && (
                    <Box gap='xxsmall'>
                      <Box
                        round='xsmall'
                        border={{ color: 'light-5' }}
                        background='white'
                      >
                        <Box
                          direction='row'
                          pad={{
                            vertical: 'xsmall',
                            horizontal: 'xsmall',
                          }}
                        >
                          <Box width='medium'>
                            <TextInput
                              plain
                              placeholder='Buscar por...'
                              value={searchBy}
                              suggestions={
                                [
                                  { id: 'searchByZipcode', name: 'CEP' },
                                  { id: 'searchByZipcodeAndAddress', name: 'Endereço' }
                                ].map(({ id, name }) => ({
                                  label: (
                                    <Box
                                      pad='small'
                                    >
                                      <Text size='small' color='dark-5'>{name}</Text>
                                    </Box>
                                  ),
                                  value: { id, name }
                                }))
                              }
                              onSuggestionSelect={event => {
                                const { id, name } = event.suggestion.value;

                                setSearchMethod(id);
                                setSearchBy(name);
                              }}
                            />
                          </Box>

                          <Box width='large'>
                            <TextInput
                              plain
                              maxLength={searchMethod === 'searchByZipcode' ? 8 : 30}
                              placeholder={
                                !searchMethod ? 'Selecione uma opção...' : (searchMethod === 'searchByZipcode' ? 'Digite o CEP' : 'Digite parte do endereço')
                              }
                              onChange={event => {
                                const { value: newValue } = event.target;
                                setSearchText(newValue);
                              }}
                              value={searchText}
                            />
                          </Box>

                          <Button
                            label={!addressOptionsLoad ? 'Buscar' : 'Busc...'}
                            size='small'
                            primary
                            disabled={!searchText || !searchMethod || addressOptionsLoad}
                            title='Selecione um tipo e defina um texto para buscar'
                            onClick={() => {
                              setSearchStarted(true);
                              onSearch({ method: searchMethod, text: searchText });
                            }}
                          />
                        </Box>
                      </Box>

                      <Box gap='small'>
                        <Box>
                          {!addressOptionsLoad && addressOptions.length <= 0 && searchStarted ? (
                            <Box
                              round='xsmall'
                              pad={{ horizontal: 'small', vertical: 'small' }}
                              border={{ color: 'light-5' }}
                              height='small'
                              overflow='auto'
                              align='center'
                              justify='center'
                            >
                              <Text size='medium' color='dark-1' margin={{ vertical: 'small' }}>
                                Nenhum endereço encontrado!
                              </Text>

                              <Text size='small' color='dark-2'>
                                Faça uma nova busca ou
                              </Text>

                              <Anchor
                                label={'cadastre manualmente'}
                                size='small'
                                onClick={() => {
                                  clearAllAddress();
                                  setManualAddress(true);
                                }}
                              />
                            </Box>
                          ) : null}

                          {!addressOptionsLoad && addressOptions && addressOptions.length > 0 ? (
                            <Box
                              round='xsmall'
                              border={{ color: 'light-5' }}
                              height='small'
                              overflow='auto'
                            >
                              {addressOptions.map((item, index) => {
                                return (
                                  <Box
                                    key={index}
                                    pad={{ horizontal: 'small', vertical: 'small' }}
                                    hoverIndicator={{ color: 'light-2' }}
                                    flex={{ shrink: 0 }}
                                    onClick={() => { setAddressAfterSearch(item) }}
                                  >
                                    <Text size='medium' color='dark-1'>{item.addressType} {item.address}</Text>
                                    <Text size='xsmall' color='dark-3'>{item.district}, {item.city} - {item.state}</Text>
                                    <Text size='xsmall' color='dark-3'>CEP: {item.zipCode}</Text>
                                  </Box>
                                )
                              })}
                            </Box>
                          ) : null}

                          <ErrorForm errorsList={postDataError.errors} fieldName='address' />
                        </Box>

                        <Box>
                          <Anchor
                            label='Cadastrar endereço manualmente'
                            size='small'
                            onClick={() => {
                              clearAllAddress();
                              setManualAddress(true);
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}


                  {/* Quando esta editando um eleitor */}
                  {address && (
                    <Box
                      border={{ color: 'light-5' }}
                      round='xsmall'
                      pad={{ horizontal: 'medium', vertical: 'medium' }}
                      background='light-1'
                    >
                      <Box>
                        <Text size='medium' color='dark-1'>{address}</Text>
                        <Text size='small' color='dark-3'>{district}, {city} - {state}</Text>
                        <Text size='small' color='dark-3'>CEP: {zipcode}</Text>
                      </Box>

                      {/* Número/Complemento */}
                      <Box
                        direction='row'
                        gap='small'
                      >
                        <Box
                          margin={{ vertical: 'small' }}
                          width='30vw'
                        >
                          <TitleField text={'Número'} />

                          <TextInput
                            style={{
                              backgroundColor: 'white'
                            }}
                            type='number'
                            min={0}
                            value={addressNumber}
                            onChange={event => changeField({ fieldName: 'addressNumber', value: event.target.value })}
                          />
                        </Box>

                        <Box
                          margin={{ vertical: 'small' }}
                          width='70vw'
                        >
                          <TitleField text={'Complemento'} />

                          <TextInput
                            style={{
                              backgroundColor: 'white'
                            }}
                            value={additionalAddress}
                            onChange={event => changeField({ fieldName: 'additionalAddress', value: event.target.value })}
                          />
                        </Box>
                      </Box>

                      <Box
                        margin={{ top: 'small' }}
                      >
                        {address && (
                          <Anchor
                            label='Trocar endereço do eleitor'
                            size='small'
                            onClick={() => { clearAllAddress() }}
                          />
                        )}
                      </Box>
                    </Box>
                  )}
                </>
              )}

              {manualAddress && (
                <>
                  {/* Endereço */}
                  <Box>
                    <TitleField text={'Endereço'} required />

                    <TextInput
                      maxLength={60}
                      autoComplete='disabled'
                      value={address}
                      onChange={event => changeField({ fieldName: 'address', value: event.target.value })}
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='address' />
                  </Box>

                  {/* Número/Complemento */}
                  <Box
                    direction='row'
                    gap='small'
                  >
                    <Box
                      margin={{ vertical: 'small' }}
                      width='30vw'
                    >
                      <TitleField text={'Número'} />

                      <TextInput
                        type='number'
                        min={0}
                        value={addressNumber}
                        onChange={event => changeField({ fieldName: 'addressNumber', value: event.target.value })}
                      />
                    </Box>

                    <Box
                      margin={{ vertical: 'small' }}
                      width='70vw'
                    >
                      <TitleField text={'Complemento'} />

                      <TextInput
                        maxLength={40}
                        value={additionalAddress}
                        onChange={event => changeField({ fieldName: 'additionalAddress', value: event.target.value })}
                      />

                      <ErrorForm errorsList={postDataError.errors} fieldName='additionalAddress' />
                    </Box>
                  </Box>

                  {/* Cidade/Bairro */}
                  <Box
                    direction='row'
                    gap='small'
                  >
                    <Box
                      margin={{ vertical: 'small' }}
                      width='50vw'
                    >
                      <TitleField text={'Cidade'} required />

                      <Select
                        emptySearchMessage='Selecione uma opção'
                        options={cityOptions}
                        value={cityId ?? ''}
                        labelKey='name'
                        valueKey={{ key: 'id', reduce: true }}
                        onChange={({ option }) => changeStateAndCity(option)}
                        onSearch={text => onSearchCities(text)}
                        icon={
                          cityOptionsLoad ? (
                            <Spinning size='medium' color='brand' kind='pulse' />
                          ) : (
                            true
                          )
                        }
                      />

                      <ErrorForm errorsList={postDataError.errors} fieldName='cityId' />
                    </Box>

                    <Box
                      margin={{ vertical: 'small' }}
                      width='50vw'
                    >
                      <TitleField text={'Bairro'} required />

                      <Select
                        searchPlaceholder={cityId ? 'Digite para buscar...' : 'Selecione uma cidade antes...'}
                        emptySearchMessage={cityId ? 'Nenhum resultado encontrado.' : 'Você ainda não selecionou uma cidade...'}
                        options={districtOptions}
                        value={districtId ?? ''}
                        labelKey='name'
                        valueKey={{ key: 'id', reduce: true }}
                        onChange={({ value: nextValue }) => changeField({ fieldName: 'districtId', value: nextValue })}
                        onSearch={text => onSearchDistricts(text)}
                        icon={
                          districtOptionsLoad ? (
                            <Spinning size='medium' color='brand' kind='pulse' />
                          ) : (
                            true
                          )
                        }
                      />

                      <ErrorForm errorsList={postDataError.errors} fieldName='districtId' />
                    </Box>
                  </Box>

                  {/* CEP */}
                  <Box margin={{ vertical: 'small' }}>
                    <TitleField text={'CEP'} required />

                    <MaskedInput
                      mask={[
                        { length: 5, regexp: /\d/ },
                        { fixed: '-' },
                        { length: 3, regexp: /\d/ }
                      ]}
                      value={zipcode ?? ''}
                      placeholder='00000 000'
                      onChange={event => changeField({ fieldName: 'zipcode', value: event.target.value })}
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='zipcode' />
                  </Box>

                  <Box>
                    <Anchor
                      label='Buscar por CEP ou endereço'
                      size='small'
                      onClick={() => {
                        clearAllAddress();
                        setManualAddress(false);
                      }}
                    />
                  </Box>
                </>
              )}
            </Box>

            {/* coluna 2 - address */}
            <Box
              margin={{
                left: size !== 'small' && size !== 'medium' ? 'large' : 'none'
              }}
              width={
                size !== 'small' &&
                size !== 'medium' ? '40vw' : '100vw'
              }
            >
              <Box
                direction='row'
                align='center'
                gap='small'
              >
                <CheckBox
                  checked={!withLocation}
                  onChange={(event) => {
                    changeField({ fieldName: 'withLocation', value: !event.target.checked })

                    if(withLocation) {
                      clearAllAddress();
                    }
                  }}
                />

                <Text
                  color='dark-1'
                  size='small'
                >
                  Não cadastrar endereço para esse eleitor.
                </Text>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ votersReducer: { formManager, selectOptions } }) => ({
  cityOptions: selectOptions.cityOptions,
  districtOptions: selectOptions.districtOptions,
  addressOptions: selectOptions.addressOptions,
  cityOptionsLoad: selectOptions.cityOptionsLoad,
  districtOptionsLoad: selectOptions.districtOptionsLoad,
  addressOptionsLoad: selectOptions.addressOptionsLoad,

  address: formManager.address,
  addressNumber: formManager.addressNumber,
  additionalAddress: formManager.additionalAddress,
  districtId: formManager.districtId,
  cityId: formManager.cityId,
  stateId: formManager.stateId,
  zipcode: formManager.zipcode,
  district: formManager.district,
  city: formManager.city,
  state: formManager.state,
  withLocation: formManager.withLocation,

  postDataError: formManager.postDataError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    changeField,
    changeStateAndCity,
    setAddressAfterSearch,
    clearAllAddress,
    searchAddressesOptions,
    searchCityOptions,
    searchDistrictOptions,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(Address);

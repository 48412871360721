import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import { getInvoices } from '../../../api/client';
import * as analytics from '../../../analytics';

export const toggleInvoicesLayer = params => {
  if(params && params.id) {
    analytics.registerEvent(
      { category: analytics.categories.customerArea, action: analytics.actions.customerAreaInvoices }
    );

    return dispatch => {
      dispatch([
        { type: COMMON_TYPES.toggleLayer, payload: 'invoices' },
        { type: TYPES.loadingFormSearch },
      ]);

      getInvoices()
        .then(({ data }) =>
          dispatch([
            { type: TYPES.searchSuccess, payload: data },
            { type: TYPES.loadingFormSearch },
          ])
        )
        .catch(() => dispatch([
            { type: TYPES.searchError },
            { type: TYPES.loadingFormSearch },
          ])
        )
    }
  }

  return [{ type: COMMON_TYPES.toggleLayer, payload: null }];
};

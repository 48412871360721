import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Text, Layer, TextArea, Anchor, Box, CheckBoxGroup, Select } from 'grommet';
import { FormClose } from 'grommet-icons';

import { ErrorForm, Tag } from '../../../../components';
import { TitleField, DescriptionField, CalendarDropButton } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import { changeValues } from '../../../../actions/form';
import { create, toggleFormReminderLayer } from '../../actions/form-reminder';

import visibleWidth from '../../../../constants/layer-width';
import selectYesNoOptions from '../../../../constants/select-yesno-options';

import { formattedDateTime } from '../../../../utils';

class FormReminder extends Component {
  callbackPostSuccess = () => {
    const {
      toggleFormReminderLayer,
    } = this.props;

    toggleFormReminderLayer();
  };

  handlePost = formParams => {
    const { create } = this.props;

    create({
      formParams,
      callbackSuccess: this.callbackPostSuccess
    });
  };

  render() {
    const {
      usersOptions,

      id,
      sourceId,
      source,
      status,
      description,
      taskDate,
      participants,
      notify,

      toggleLayer,
      loadingPostForm,
      postDataError,

      changeValues,
      toggleFormReminderLayer,
    } = this.props;

    if(toggleLayer && toggleLayer === 'reminder') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleFormReminderLayer}
              onEsc={toggleFormReminderLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <Box
                  direction='row'
                  justify='between'
                  align='baseline'
                  margin={{ horizontal: 'large' }}
                >
                  <TitleDetail value='Criar lembrete' />

                  <Button
                    plain
                    title='Fechar'
                    icon={<FormClose />}
                    onClick={toggleFormReminderLayer}
                  />
                </Box>


                <Box flex overflow='auto'>
                  {/* Responsáveis */}
                  <Box
                    margin={{
                      vertical: 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Responsáveis' required />

                    <Box
                      round='xxsmall'
                      pad={{
                        vertical: 'small',
                      }}
                      gap='xsmall'
                    >
                      <CheckBoxGroup
                        name='participants'
                        value={participants ?? []}
                        options={
                          usersOptions
                            .filter(a => a.status || participants?.some(userId => userId === a.id))
                            .map(({ name, id }) => ({
                                l: name,
                                value: id,
                              })
                            )
                        }
                        onChange={({ value: nextValue }) => {
                          changeValues({ fieldName: 'participants', value: nextValue }, 'formChangeValues')
                        }}
                      >
                        {(option, { checked }) => {
                          let background = null;
                          let borderColor = 'dark-5';

                          if(checked) {
                            background = 'brand';
                            borderColor = 'brand';
                          }

                          return (
                            <Box
                              direction='row'
                              gap='small'
                              justify='center'
                              align='center'
                            >
                              <Box
                                width='20px'
                                height='20px'
                                background={background}
                                round='xxsmall'
                                border={{
                                  color: borderColor
                                }}
                              />
                              <Text color='dark-2' size='small'>{option.l}</Text>
                            </Box>
                          )
                        }}
                      </CheckBoxGroup>
                    </Box>

                    <ErrorForm errorsList={postDataError.errors} fieldName='participants' />
                  </Box>


                  {/* Data lembrete */}
                  <Box
                    margin={{
                      vertical: 'small',
                      horizontal: 'large'
                    }}
                    direction={ size === 'small' ? 'column' : 'row' }
                    align='stretch'
                    flex={{ shrink: 0 }}
                    gap='small'
                  >
                    <Box
                      width={ size === 'small' ? '100vw' : '50vw'}
                      gap='xxsmall'
                    >
                      <TitleField
                        text='Data'
                        required
                      />

                      <DescriptionField
                        text='Quando deseja ser lembrado.'
                      />
                    </Box>

                    <Box
                      width={ size === 'small' ? '100vw' : '50vw'}
                    >
                      <CalendarDropButton
                        date={taskDate ?? ''}
                        onSelect={(selectedDate) => changeValues({ fieldName: 'taskDate', value: selectedDate }, 'formChangeValues')}
                      />

                      <ErrorForm errorsList={postDataError.errors} fieldName='taskDate' />
                    </Box>
                  </Box>


                  {/* Lembrete por WhatsApp */}
                  {!!parseInt(process.env.REACT_APP_NOTIFICATION_WHATSAPP) ? (
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                      gap='small'
                    >
                      <Box
                        direction={ size === 'small' ? 'column' : 'row' }
                        gap='small'
                        align='stretch'
                      >
                        <Box
                          width={ size === 'small' ? '100vw' : '50vw'}
                          gap='xxsmall'
                        >
                          <TitleField
                            text='Notificar por WhatsApp?'
                          />

                          <DescriptionField
                            text='Os responsáveis serão notificados no dia da tarefa.'
                          />
                        </Box>

                        <Box
                          width={ size === 'small' ? '100vw' : '50vw'}
                        >
                          <Select
                            options={selectYesNoOptions}
                            value={notify}
                            labelKey='name'
                            valueKey={{ key: 'id', reduce: true }}
                            disabled={ participants?.length === 0 }
                            title={ participants?.length === 0 ? 'Selecione os responsáveis' : null }
                            onChange={({ value: nextValue }) =>
                              changeValues({ fieldName: 'notify', value: nextValue }, 'formChangeValues')
                            }
                          />
                        </Box>
                      </Box>

                      {notify && (
                        <Box
                          round={ size === 'small' ? 'xxsmall' : 'xsmall' }
                          border={{ color: 'light-4' }}
                        >
                          {participants.map((userId) => {
                            const { id, name, phone } = usersOptions.find(a => a.id === userId);

                            return (
                              <Box
                                key={id}
                                direction='row'
                                justify='between'
                                pad={{
                                  vertical: size === 'small' ? 'medium' : 'small',
                                  horizontal: size === 'small' ? 'medium' : 'small',
                                }}
                                border={{
                                  color: 'light-4',
                                  side: 'bottom',
                                }}
                              >
                                <Text size='small' color='dark-2'>{name}</Text>

                                {phone ?
                                  <Tag
                                    text={phone}
                                    textSize='xsmall'
                                    background='#d5ffe8'
                                    textColor='neutral-1'
                                  />
                                  : <Tag
                                    text='sem celular'
                                    textSize='xsmall'
                                    background='#fff4dd'
                                    textColor='status-warning'
                                  />
                                }
                              </Box>
                            )
                          })}
                        </Box>
                      )}
                    </Box>
                  ) : null}


                  {/* descricao */}
                  <Box
                    margin={{
                      vertical: 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Descrição' required />

                    <TextArea
                      rows={4}
                      resize='vertical'
                      maxLength={200}
                      value={description ?? ''}
                      onChange={event =>
                        changeValues({ fieldName: 'description', value: event.target.value }, 'formChangeValues')
                      }
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='description' />
                  </Box>
                </Box>


                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='xsmall'>
                    <Anchor
                      color='dark-3'
                      label='Cancelar'
                      disabled={ loadingPostForm }
                      onClick={toggleFormReminderLayer}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label={ !loadingPostForm ? 'Salvar': 'Salvando...' }
                      disabled={ loadingPostForm }
                      onClick={() =>
                        this.handlePost({
                          id,
                          sourceId,
                          source,
                          status,
                          description,
                          taskDate: formattedDateTime(taskDate),
                          participants,
                          notify,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ layerReducer, selectOptionsReducer, formCreateUpdateCopyGeneric }) => ({
  usersOptions: selectOptionsReducer.usersOptions,

  id: formCreateUpdateCopyGeneric.id,
  sourceId: formCreateUpdateCopyGeneric.sourceId,
  source: formCreateUpdateCopyGeneric.source,
  status: formCreateUpdateCopyGeneric.status,
  description: formCreateUpdateCopyGeneric.description,
  taskDate: formCreateUpdateCopyGeneric.taskDate,
  participants: formCreateUpdateCopyGeneric.participants,
  notify: formCreateUpdateCopyGeneric.notify,

  loadingPostForm: formCreateUpdateCopyGeneric.loadingPostForm,
  postDataError: formCreateUpdateCopyGeneric.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    create,
    changeValues,
    toggleFormReminderLayer,
  }, dispatch);

export const Reminder = connect(mapStateToProps, mapDispatchToProps)(FormReminder);

import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import { showNotificationLayer } from '../../../actions/notification';
import { createProfile } from '../../../api/client';

const loadingPost = () => ({ type: COMMON_TYPES.formPostWatingResponse });

export const create = params => {
  return dispatch => {
    dispatch(loadingPost());

    createProfile(params.formParams)
      .then(response => {
        dispatch(loadingPost());
        dispatch(hideFormManagerLayer());

        dispatch({
          type: TYPES.insertItemList,
          payload: response.data,
        });

        dispatch(showNotificationLayer('Solicitação enviada!'));
      })
      .catch(responseFail => {
        dispatch(loadingPost());
        dispatch({
          type: COMMON_TYPES.formPostResultError,
          payload: responseFail.response.data,
        });
      })
  }
};

export const showFormManagerLayer = () => {
  return { type: COMMON_TYPES.toggleLayer, payload: 'manager' }
};

export const hideFormManagerLayer = () => [
  { type: COMMON_TYPES.toggleLayer, payload: null },
  { type: COMMON_TYPES.formClearValues },
];

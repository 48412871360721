import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Layer, Text, Box, Anchor, Button } from 'grommet';
import { Document, FormClose } from 'grommet-icons';

import { Placeholder } from '../../../../components';
import { SimpleDetail, Hr, TagStatus, Description, TitleDetail } from '../../../../components/detail';

import { formattedDateTime } from '../../../../utils';
import visibleWidth from '../../../../constants/layer-width';

import { toggleDetailsLayer } from '../../actions/details';

class DetailsComponent extends Component {
  render() {
    const {
      detail,
      getDetailError,
      toggleLayer,

      toggleDetailsLayer,
    } = this.props;

    if(toggleLayer && toggleLayer === 'details') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleDetailsLayer}
              onEsc={toggleDetailsLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{
                  top: size === 'small' ? 'xlarge' : 'large',
                  bottom: size === 'small' ? 'large' : null,
                }}
                overflow='auto'
                flex
              >
                {getDetailError ? (
                  <Placeholder
                    title='Ops! Algo deu errado :('
                    message='Estamos constrangidos por isso.'
                    button={{
                      label: 'Fechar',
                      action: toggleDetailsLayer,
                    }}
                  />
                ) : null}

                {!detail && !getDetailError ? (
                  <Placeholder
                    title='Carregando...'
                    message='Aguarde que estamos preparando as coisas :)'
                  />
                ) : null}

                {detail ? (
                  <>
                    <Box
                      direction='row'
                      justify='between'
                      align='baseline'
                      margin={{ horizontal: 'large' }}
                    >
                      <TitleDetail value={`Detalhes do atendimento ${detail.reference}`} />

                      <Button
                        plain
                        title='Fechar'
                        icon={<FormClose />}
                        onClick={toggleDetailsLayer}
                      />
                    </Box>


                    <Box flex overflow='auto'>
                      <TagStatus
                        title='Status'
                        value={detail.statusName}
                        color={detail.statusColor}
                      />

                      <SimpleDetail
                        title='Categoria'
                        value={detail.categoryName}
                      />

                      <SimpleDetail
                        title='Origem'
                        value={detail.originName}
                      />

                      <SimpleDetail
                        title='Escritório'
                        value={detail.officeName}
                      />

                      <SimpleDetail
                        title='Secretária'
                        value={detail.departmentName}
                      />

                      <Hr />

                      {detail.requestDate && <SimpleDetail
                        title='Data de solicitação'
                        value={formattedDateTime(detail.requestDate, 'ddd, DD MMM YYYY')}
                      />}

                      {detail.completionDate && <SimpleDetail
                        title='Data de conclusão'
                        value={formattedDateTime(detail.completionDate, 'ddd, DD MMM YYYY')}
                      />}

                      <Hr />

                      <SimpleDetail
                        title='Data de cadastro'
                        value={formattedDateTime(detail.createdAt, 'ddd, DD MMM YYYY')}
                      />

                      <SimpleDetail
                        title='Última atualização'
                        value={formattedDateTime(detail.updatedAt, 'ddd, DD MMM YYYY')}
                      />

                      <Hr />

                      <Description
                        title='Descrição'
                        value={detail.description}
                      />

                      <Hr />

                      {detail.files && detail.files.length > 0 &&
                        <>
                          <Box
                            margin={{
                              top: 'xsmall',
                              bottom: 'none',
                              horizontal: 'large',
                            }}
                            pad={{ bottom: 'small' }}
                            flex={{ shrink: 0 }}
                          >
                            <Text size='medium' color='dark-2' margin={{ bottom: 'medium' }}>
                              Arquivos
                            </Text>

                            <Box
                              direction='row'
                              wrap={true}
                            >
                              {
                                detail.files.map((item, index) => (
                                  <Box
                                    key={index}
                                    width='large'
                                    round='xsmall'
                                    pad={{
                                      vertical: size === 'small' ? 'medium' : 'small',
                                      horizontal: 'medium'
                                    }}
                                    margin={{ bottom: 'medium' }}
                                    border={{ color: 'light-3' }}
                                    gap='medium'
                                    direction='row'
                                    align='center'
                                  >
                                    <Document />

                                    <Anchor
                                      label={item.fileName}
                                      href={item.url}
                                      target='_blank'
                                    />
                                  </Box>
                                ))
                              }
                            </Box>
                          </Box>

                          <Hr />
                        </>
                      }

                      {detail.responsibles && detail.responsibles.length > 0 &&
                        <>
                          <Box
                            margin={{
                              top: 'xsmall',
                              bottom: 'none',
                              horizontal: 'large',
                            }}
                            pad={{ bottom: 'small' }}
                            flex={{ shrink: 0 }}
                          >
                            <Text size='medium' color='dark-2' margin={{ bottom: 'medium' }}>
                              {detail.responsibles.length} {detail.responsibles.length > 1 ? 'responsáveis' : 'responsável'}
                            </Text>

                            <Box
                              direction='row'
                              wrap={true}
                            >
                              {
                                detail.responsibles.map((responsible, index) => (
                                  <Box
                                    key={index}
                                    width={{ min: 'small', max: 'medium' }}
                                    round='xsmall'
                                    pad={{ vertical: 'medium', horizontal: 'medium' }}
                                    margin={{ bottom: 'medium', horizontal: 'xsmall' }}
                                    border={{ color: 'light-3' }}
                                  >
                                    <Box margin={{ horizontal: 'small' }}>
                                      <Text size='medium' color='dark-2'>{responsible.userName}</Text>
                                      <Text size='xsmall' color='dark-5' truncate>{responsible.userEmail}</Text>
                                    </Box>
                                  </Box>
                                ))
                              }
                            </Box>
                          </Box>

                          <Hr />
                        </>
                      }

                      {detail.documents && detail.documents.length > 0 &&
                        <>
                          <Box
                            margin={{
                              top: 'xsmall',
                              bottom: 'none',
                              horizontal: 'large',
                            }}
                            pad={{ bottom: 'small' }}
                            flex={{ shrink: 0 }}
                          >
                            <Text size='medium' color='dark-2' margin={{ bottom: 'medium' }}>
                              Requerimentos, ofícios, indicações e moções
                            </Text>

                            <Box
                              direction='row'
                              gap='small'
                              wrap={true}
                            >
                              {
                                detail.documents.map((document, index) => (
                                  <Box
                                    key={index}
                                    gap='small'
                                    round='large'
                                    pad={{ vertical: 'small', horizontal: 'medium' }}
                                    margin={{ bottom: 'small' }}
                                    border={{ color: 'light-3' }}
                                  >
                                    <Box direction='row' align='center' gap='small'>
                                      <Text size='small' color='dark-2' >
                                        {document.typeName}
                                      </Text>

                                      <Text size='small' color='dark-5'>
                                        {document.number}/{document.year}
                                      </Text>
                                    </Box>
                                  </Box>
                                ))
                              }
                            </Box>
                          </Box>

                          <Hr />
                        </>
                      }

                      {detail.progress && detail.progress.length > 0 &&
                        <Box
                          margin={{
                            top: 'xsmall',
                            bottom: 'none',
                            horizontal: 'large',
                          }}
                          pad={{ bottom: 'small' }}
                          flex={{ shrink: 0 }}
                        >
                          <Text size='medium' color='dark-2' margin={{ bottom: 'medium' }}>
                            Andamentos
                          </Text>

                          <Box
                            direction='row'
                            wrap={true}
                          >
                            {
                              detail.progress.map((item, index) => (
                                <Box
                                  key={index}
                                  width='large'
                                  round='xsmall'
                                  pad={{
                                    vertical: size === 'small' ? 'medium' : 'small',
                                    horizontal: 'medium'
                                  }}
                                  margin={{ bottom: 'medium' }}
                                  border={{ color: 'light-3' }}
                                >
                                  <Box
                                    direction='row'
                                    align='baseline'
                                    justify='between'
                                    margin={{ bottom: 'small' }}
                                  >
                                    <Text size='medium' color='dark-2'>{item.userResponsibleName}</Text>
                                    <Text size='small' color='dark-5'>{formattedDateTime(item.progressDate, 'ddd, DD MMM YYYY')}</Text>
                                  </Box>

                                  <Box>
                                    <Text size='small' color='dark-4'>
                                      {item.description}
                                    </Text>
                                  </Box>
                                </Box>
                              ))
                            }
                          </Box>
                        </Box>
                      }
                    </Box>
                  </>
                ) : null}
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ layerDetailsReducer, layerReducer }) => ({
  detail: layerDetailsReducer.detail,
  getDetailError: layerDetailsReducer.getDetailError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleDetailsLayer,
  }, dispatch);

export const Details = connect(mapStateToProps, mapDispatchToProps)(DetailsComponent);

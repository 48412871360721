import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Layer, Box, Button, Text } from 'grommet';
import { FormClose } from 'grommet-icons';

import { Placeholder } from '../../../../components';
import {  TitleDetail } from '../../../../components/detail';

import { formattedDateTime, formattedCurrency } from '../../../../utils';
import visibleWidth from '../../../../constants/layer-width';

import { toggleInvoicesLayer } from '../../actions/invoices';

class Invoices extends Component {
  printBankSlip = (bankSlipUrl) => {
    window.open(bankSlipUrl, '_blank');
  };

  render() {
    const {
      loadingFormSearch,
      dataRows,
      totalOfRows,
      getListError,

      toggleLayer,
      toggleInvoicesLayer,
    } = this.props;


    if(!toggleLayer || toggleLayer !== 'invoices') {
      return null;
    }

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Layer
            responsive={false}
            full='vertical'
            position='left'
            onClickOutside={toggleInvoicesLayer}
            onEsc={toggleInvoicesLayer}
          >
            <Box
              fill='vertical'
              width={visibleWidth[size]}
              pad={{
                top: size === 'small' ? 'xlarge' : 'large',
                bottom: size === 'small' ? 'large' : null,
              }}
              overflow='auto'
              flex
            >
              {getListError ? (
                <Placeholder
                  title='Ops! Algo deu errado :('
                  message='Estamos constrangidos por isso.'
                  button={{
                    label: 'Fechar',
                    action: toggleInvoicesLayer,
                  }}
                />
              ) : null}

              {loadingFormSearch && !getListError ? (
                <Placeholder
                  title='Carregando...'
                  message='Aguarde que estamos preparando as coisas :)'
                />
              ) : null}

              {totalOfRows <= 0 && !loadingFormSearch ? (
                <Placeholder
                  title='Você não possui faturas'
                  message='Por hoje esta tudo tranquilo :)'
                  button={{
                    label: 'Fechar',
                    action: toggleInvoicesLayer,
                  }}
                />
              ) : null}

              {totalOfRows > 0 && !getListError ?  (
                <>
                  <Box
                    direction='row'
                    justify='between'
                    align='baseline'
                    margin={{ horizontal: 'large' }}
                  >
                    <TitleDetail value='Faturas e compras' />

                    <Button
                      plain
                      title='Fechar'
                      icon={<FormClose />}
                      onClick={toggleInvoicesLayer}
                    />
                  </Box>

                  <Box flex overflow='auto'>
                    <Box
                      margin={{
                        top: 'xsmall',
                        horizontal: 'large',
                      }}
                      pad={{ bottom: 'small' }}
                      flex={{ shrink: 0 }}
                      gap='small'
                    >
                      {dataRows.map(({
                        id, serviceName, dueDate, payDay, value, statusId, statusName, bankSlipUrl
                      }) => (
                        <Box
                          key={id}
                          round={ size === 'small' ? 'small' : 'xsmall' }
                          pad={ size === 'small' ? 'medium' : 'small' }
                          gap={ size === 'small' ? 'small' : 'xsmall' }
                          border={{
                            color: statusId === 1 ? 'light-6' : 'neutral-4',
                          }}
                        >
                          <Box
                            direction='row'
                            justify='between'
                            gap='small'
                            pad={{ bottom: 'small' }}
                          >
                            <Text size='medium'>{serviceName}</Text>
                            <Text size='small'>{statusName}</Text>
                          </Box>

                          <Box
                            direction='row'
                            justify='between'
                            gap='small'
                          >
                            <Text size='small' color='dark-2'>Valor</Text>
                            <Text size='small' color='dark-5'>{formattedCurrency(value)}</Text>
                          </Box>

                          <Box
                            direction='row'
                            justify='between'
                            gap='small'
                          >
                            <Text size='small' color='dark-2'>Vencimento</Text>
                            <Text size='small' color='dark-5'>{formattedDateTime(dueDate, 'ddd, DD MMM YYYY')}</Text>
                          </Box>

                          {statusId === 1 && (
                            <Box
                              direction='row'
                              justify='between'
                              gap='small'
                            >
                              <Text size='small' color='dark-2'>Pagamento</Text>
                              <Text size='small' color='dark-5'>{formattedDateTime(payDay, 'ddd, DD MMM YYYY')}</Text>
                            </Box>
                          )}

                          {statusId !== 1 && (
                            <Box
                              direction='row'
                              justify='end'
                              pad={{ top: 'small' }}
                            >
                              <Button
                                primary
                                size='small'
                                label='Pagar'
                                color='neutral-4'
                                onClick={() =>
                                  this.printBankSlip(bankSlipUrl)
                                }
                              />
                            </Box>
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </>
              ) : null}
            </Box>
          </Layer>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

const mapStateToProps = ({ customerAreaReducer: { searchInvoice }, layerReducer }) => ({
  loadingFormSearch: searchInvoice.loadingFormSearch,
  dataRows: searchInvoice.dataRows,
  totalOfRows: searchInvoice.totalOfRows,
  getListError: searchInvoice.getListError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleInvoicesLayer,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);

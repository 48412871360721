import { getCookie, decryptAes, dateInterfere } from '../../../utils';

export default object => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return {
    id: null,
    sourceId: object.id,
    source: 'expense',
    status: 'pending',
    description: `Lembrete despesa ${object.reference}.`,
    notify: false,
    taskDate: dateInterfere({
      date: new Date(),
      quantity: 5,
      interval: 'days',
    }),
    participants: [decipher?.user?.id],
  }
};

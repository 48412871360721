import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Layer, Box, Text, Button } from 'grommet';
import { FormClose } from 'grommet-icons';

import { Placeholder, Tag } from '../../../../components';
import { SimpleDetail, Hr, Description, TitleDetail, TagStatus } from '../../../../components/detail';

import { formattedDateTime } from '../../../../utils';
import statusOptions from '../../constants/status-options';
import visibleWidth from '../../../../constants/layer-width';

import { toggleDetailsLayer } from '../../actions/details';

class DetailsExpense extends Component {
  render() {
    const {
      detail,
      getDetailError,
      toggleLayer,

      toggleDetailsLayer,
    } = this.props;


    if(toggleLayer && toggleLayer === 'details') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleDetailsLayer}
              onEsc={toggleDetailsLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{
                  top: size === 'small' ? 'xlarge' : 'large',
                  bottom: size === 'small' ? 'large' : null,
                }}
                overflow='auto'
                flex
              >
                {getDetailError ? (
                  <Placeholder
                    title='Ops! Algo deu errado :('
                    message='Estamos constrangidos por isso.'
                    button={{
                      label: 'Fechar',
                      action: toggleDetailsLayer,
                    }}
                  />
                ) : null}

                {!detail && !getDetailError ? (
                  <Placeholder
                    title='Carregando...'
                    message='Aguarde que estamos preparando as coisas :)'
                  />
                ) : null}

                {detail ? (
                  <>
                    <Box
                      direction='row'
                      justify='between'
                      align='baseline'
                      margin={{ horizontal: 'large' }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleDetail value='Detalhes da tarefa' />

                      <Button
                        plain
                        title='Fechar'
                        icon={<FormClose />}
                        onClick={toggleDetailsLayer}
                      />
                    </Box>


                    <Box flex overflow='auto'>
                      <SimpleDetail
                        title='Dia'
                        value={formattedDateTime(detail.taskDate, 'ddd, DD MMM YYYY')}
                      />

                      <TagStatus
                        title='Status'
                        value={statusOptions.find(item => item.id === detail.status)?.name}
                        color={statusOptions.find(item => item.id === detail.status)?.color}
                      />

                      <Hr />

                      <Description title='Descrição' value={detail.description} />

                      <Hr />

                      {detail.participants && detail.participants.length > 0 &&
                        <Box
                          margin={{
                            top: 'xsmall',
                            bottom: 'none',
                            horizontal: 'large'
                          }}
                          pad={{ bottom: 'small' }}
                          flex={{ shrink: 0 }}
                        >
                          <Text size='medium' color='dark-2' margin={{ bottom: 'medium' }}>
                            Responsáveis
                          </Text>

                          <Box
                            direction='row'
                            flex={{ shrink: 0 }}
                            wrap={true}
                            gap='small'
                          >
                            {
                              detail.participants.map((participant, index) => (
                                <Box
                                  key={index}
                                  border={{ color: 'light-4' }}
                                  round='xsmall'
                                  background='light-1'
                                  pad={{ vertical: 'small', horizontal: 'medium' }}
                                  flex={{ shrink: 0 }}
                                  margin={{ bottom: 'small' }}
                                >
                                  <Text size='medium' color='dark-2'>
                                    {participant.name}
                                  </Text>
                                </Box>
                              ))
                            }
                          </Box>
                        </Box>
                      }

                      {detail.notify && detail.participants.length > 0 && (
                        <>
                          <Hr />

                          <Box
                            margin={{
                              top: 'xsmall',
                              bottom: 'none',
                              horizontal: 'large'
                            }}
                            pad={{ bottom: 'small' }}
                            flex={{ shrink: 0 }}
                          >
                            <Text size='medium' color='dark-2'>
                              Notificação por WhatsApp
                            </Text>

                            <Text size='xsmall' color='dark-4' margin={{ bottom: 'medium' }}>
                              Usuários sem celular não receberão notificação.
                            </Text>

                            <Box
                              round={ size === 'small' ? 'xxsmall' : 'xsmall' }
                              border={{ color: 'light-4' }}
                            >
                              {
                                detail.participants.map(({ userId, name, phone }) => (
                                  <Box
                                    key={userId}
                                    direction='row'
                                    justify='between'
                                    pad={{
                                      vertical: size === 'small' ? 'medium' : 'small',
                                      horizontal: size === 'small' ? 'medium' : 'small',
                                    }}
                                    border={{
                                      color: 'light-4',
                                      side: 'bottom',
                                    }}
                                  >
                                    <Text size='small' color='dark-2'>{name}</Text>

                                    {phone ? (
                                      <Tag
                                        text={phone}
                                        textSize='xsmall'
                                        background='#d5ffe8'
                                        textColor='neutral-1'
                                      />
                                    ) : (
                                      <Tag
                                        text='sem celular'
                                        textSize='xsmall'
                                        background='#fff4dd'
                                        textColor='status-warning'
                                      />
                                    )}
                                  </Box>
                                ))
                              }
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                  </>
                ) : null}
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ layerDetailsReducer, layerReducer }) => ({
  detail: layerDetailsReducer.detail,
  getDetailError: layerDetailsReducer.getDetailError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleDetailsLayer,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DetailsExpense);

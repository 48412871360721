import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, TextInput, Select, TextArea, Anchor } from 'grommet';

import { TitleField, SelectSearch } from '../../../../components/form';

import { getOccupationOptions } from '../../../../actions/select-options';
import { changeField } from '../../actions/form-create';

import selectActiveInactiveOptions from '../../../../constants/select-active-inactive-options';
import selectMaritalStatusOptions from '../../../../constants/select-marital-status-options';

const Additional = (props) => {
  const {
    occupationOptions,

    id,
    status,
    sonsIn,
    maritalStatusId,
    professionId,
    profession,
    note,

    changeField,
  } = props;

  const onSearchOccupations = useCallback(text => {
    if (text.length > 2) {
      props.getOccupationOptions({ name: text });
    }
  }, [props]);

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box margin={{ bottom: 'medium' }}>
            <Text size='large'>
              Dados adicionais
            </Text>
          </Box>

          <Box
            direction={size !== 'small' && size !== 'medium' ? 'row' : 'column-reverse'}
          >
            {/* coluna 1 - settings */}
            <Box
              margin={{ right: 'large' }}
              width={size !== 'small' && size !== 'medium' ? '50vw' : '100vw'}
            >

              {/* Status/Quantidade de filhos/Estado Civil */}
              <Box
                direction={ size === 'small' ? 'column' : 'row' }
                gap='small'
              >
                <Box
                  margin={{ vertical: 'small' }}
                  width={ size === 'small' ? '100vw' : '30vw' }
                >
                  <TitleField text={'Status'} />

                  <Select
                    options={selectActiveInactiveOptions}
                    value={status}
                    labelKey='name'
                    valueKey={{ key: 'id', reduce: true }}
                    emptySearchMessage='Selecione uma opção'
                    onChange={({ value: nextValue }) => changeField({ fieldName: 'status', value: nextValue })}
                  />
                </Box>

                <Box
                  margin={{ vertical: 'small' }}
                  width={ size === 'small' ? '100vw' : '20vw' }
                >
                  <TitleField text={'Qtde de filhos'} />

                  <TextInput
                    type='number'
                    min={0}
                    value={sonsIn ?? ''}
                    onChange={event => changeField({ fieldName: 'sonsIn', value: event.target.value })}
                  />
                </Box>

                <Box
                  margin={{ vertical: 'small' }}
                  width={ size === 'small' ? '100vw' : '50vw' }
                >
                  <Box direction='row' align='center' gap='xsmall'>
                    <TitleField text={'Estado Civil'} />

                    {maritalStatusId && (
                      <Anchor
                        size='small'
                        label='limpar'
                        onClick={() => changeField({ fieldName: 'maritalStatusId', value: null })}
                      />
                    )}
                  </Box>

                  <SelectSearch
                    options={selectMaritalStatusOptions}
                    value={maritalStatusId ?? ''}
                    labelKey='name'
                    valueKey={{ key: 'id', reduce: true }}
                    emptySearchMessage='Selecione uma opção'
                    onChange={({ value: nextValue }) =>
                      changeField({ fieldName: 'maritalStatusId', value: nextValue })
                    }
                  />
                </Box>
              </Box>

              {/* Profissão */}
              <Box margin={{ vertical: 'small' }}>
                <Box direction='row' align='center' gap='xsmall'>
                  <TitleField text={'Profissão'} />

                  {professionId && (
                    <Anchor
                      size='small'
                      label='limpar'
                      onClick={() => changeField({ fieldName: 'professionId', value: null })}
                    />
                  )}
                </Box>

                <Select
                  searchPlaceholder='Digite parte do nome para buscar...'
                  emptySearchMessage='Digite para buscar...'
                  options={
                    id && professionId ? [{ id: professionId, name: profession }] : occupationOptions
                  }
                  value={professionId ?? ''}
                  labelKey='name'
                  valueKey={{ key: 'id', reduce: true }}
                  onChange={({ value: nextValue }) =>
                    changeField({ fieldName: 'professionId', value: nextValue })
                  }
                  onSearch={text =>
                    onSearchOccupations(text)
                  }
                />
              </Box>


              <Box margin={{ vertical: 'small' }}>
                <TitleField text={'Observações'} />

                <TextArea
                  maxLength={1000}
                  resize='vertical'
                  value={note ?? ''}
                  onChange={event => changeField({ fieldName: 'note', value: event.target.value })}
                />
              </Box>

            </Box>

            {/* coluna 2 - settings */}
            <Box
              margin={{
                bottom: 'small',
                left: size !== 'small' && size !== 'medium' ? 'large' : 'none'
              }}
              width={
                size !== 'small' &&
                size !== 'medium' ? '40vw' : '100vw'
              }
            />
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ selectOptionsReducer, votersReducer: { formManager } }) => ({
  occupationOptions: selectOptionsReducer.occupationOptions,

  id: formManager.id,
  name: formManager.name,
  individualRegistration: formManager.individualRegistration,
  status: formManager.status,
  sonsIn: formManager.sonsIn,
  maritalStatusId: formManager.maritalStatusId,
  professionId: formManager.professionId,
  profession: formManager.profession,
  note: formManager.note,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    changeField,
    getOccupationOptions,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(Additional);

import TYPES from '../actions/types';

const INITIAL_STATE = {
  summary: null,
  profileDisplay: null,
  getSummaryError: false,
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.summaryData:
      return {
        ...state,
        summary: action.payload.summary,
        profileDisplay: action.payload.profile,
        getSummaryError: false,
      };

    case TYPES.summaryRequestError:
      return { ...state, getSummaryError: true };

    default:
      return state;
  }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, TextArea, Text, Box, MaskedInput, Select, TextInput, Anchor, CheckBoxGroup } from 'grommet';
import { Clock, Phone, FormClose } from 'grommet-icons';

import { Placeholder, ErrorForm, Tag } from '../../../../components';
import { TitleField, DescriptionField, CalendarDropButton } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import { searchAppointments } from '../../actions/form-search';
import { update, create, hideFormManagerLayer } from '../../actions/form-manager';
import { changeValues } from '../../../../actions/form';

import selectHourOptions from '../../constants/select-hour-options';
import selectYesNoOptions from '../../../../constants/select-yesno-options';

import visibleWidth from '../../../../constants/layer-width';

import { formattedDateTime } from '../../../../utils';

class FormCreate extends Component {
  callbackPostSuccess = () => {
    const {
      searchAppointments,
      hideFormManagerLayer,
    } = this.props;

    searchAppointments();
    hideFormManagerLayer();
  };

  handlePost = formParams => {
    const { isEditing, create, update } = this.props;

    if(isEditing && formParams.id) {
      update({ formParams });
    }
    else {
      create({
        formParams,
        callbackSuccess: this.callbackPostSuccess
      });
    }
  };

  render() {
    const {
      usersOptions,
      integrationUserOptions,

      id,
      subject,
      date,
      description,
      hourStart,
      hourEnd,
      participant,
      requesterName,
      requesterPhone,
      location,
      notify,
      integrate,
      isEditing,

      getServerPresponseError,
      watingServerResponse,

      toggleLayer,
      loadingPostForm,
      postDataError,

      changeValues,
      hideFormManagerLayer,
    } = this.props;


    if(!toggleLayer || toggleLayer !== 'manager')
      return null;

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Layer
            responsive={false}
            full='vertical'
            position='left'
            onClickOutside={hideFormManagerLayer}
            onEsc={hideFormManagerLayer}
          >
            <Box
              fill='vertical'
              width={visibleWidth[size]}
              pad={{ top: 'large', bottom: 'xsmall' }}
              overflow='auto'
              flex
            >
              {watingServerResponse && !id && !getServerPresponseError ? (
                <Placeholder
                  title='Carregando...'
                  message='Aguarde que estamos preparando as coisas :)'
                />
              ) : null}

              {getServerPresponseError ? (
                <Placeholder
                  title='Ops! Algo deu errado :('
                  message='Estamos constrangidos por isso.'
                  button={{
                    label: 'Fechar',
                    action: hideFormManagerLayer,
                  }}
                />
              ) : null}

              {!getServerPresponseError && (id || !watingServerResponse) ? (
                <>
                  <Box
                    direction='row'
                    justify='between'
                    align='baseline'
                    margin={{ horizontal: 'large' }}
                  >
                    <TitleDetail value={isEditing && id ? 'Editar compromisso' : 'Novo compromisso'} />

                    <Button
                      plain
                      title='Fechar'
                      icon={<FormClose />}
                      onClick={hideFormManagerLayer}
                    />
                  </Box>


                  <Box flex overflow='auto'>
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Dia e horário' required />

                      <Box direction='row' gap='small'>
                        <Box width='40vw'>
                          <CalendarDropButton
                            date={date ?? ''}
                            onSelect={(selectedDate) =>
                              changeValues({ fieldName: 'date', value: selectedDate }, 'formChangeValues')
                            }
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='date' />
                        </Box>

                        <Box width='40vw'>
                          <Select
                            options={selectHourOptions}
                            value={hourStart ?? ''}
                            placeholder='Inicia as...'
                            labelKey='name'
                            valueKey={{ key: 'id', reduce: true }}
                            icon={<Clock />}
                            onChange={({ value: nextValue }) =>
                              changeValues({ fieldName: 'hourStart', value: nextValue }, 'formChangeValues')
                            }
                            emptySearchMessage='Selecione uma opção'
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='hourStart' />
                        </Box>

                        <Box width='40vw'>
                          <Select
                            options={selectHourOptions}
                            value={hourEnd ?? ''}
                            placeholder='Termina as...'
                            labelKey='name'
                            valueKey={{ key: 'id', reduce: true }}
                            icon={<Clock />}
                            onChange={({ value: nextValue }) =>
                              changeValues({ fieldName: 'hourEnd', value: nextValue }, 'formChangeValues')
                            }
                            emptySearchMessage='Selecione uma opção'
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='hourEnd' />
                        </Box>
                      </Box>
                    </Box>


                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Assunto' required />

                      <TextInput
                        maxLength={60}
                        value={subject ?? ''}
                        onChange={event =>
                          changeValues({ fieldName: 'subject', value: event.target.value }, 'formChangeValues')
                        }
                      />

                      <ErrorForm errorsList={postDataError.errors} fieldName='subject' />
                    </Box>


                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Local' />

                      <TextInput
                        maxLength={100}
                        value={location ?? ''}
                        onChange={event =>
                          changeValues({ fieldName: 'location', value: event.target.value }, 'formChangeValues')
                        }
                      />

                      <ErrorForm errorsList={postDataError.errors} fieldName='location' />
                    </Box>

                    <Box
                      border={{ color: 'light-3', side: 'bottom' }}
                      margin={{ top: 'medium', bottom: 'medium' }}
                    />

                    {/* Participantes */}
                    <Box
                      margin={{
                        vertical: size === 'small' ? 'medium' : 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Participantes' />

                      <Box
                        round='xxsmall'
                        pad={{
                          vertical: 'small',
                        }}
                        gap='xsmall'
                      >
                        <CheckBoxGroup
                          width={{ max: '100%' }}
                          labelKey='label'
                          valueKey='id'
                          value={participant}
                          onChange={({ value: nextValue }) => {
                            changeValues({ fieldName: 'participant', value: nextValue }, 'formChangeValues')
                          }}
                          options={
                            usersOptions.filter(
                              a => a.status || participant.some(userId => userId === a.id)
                            ).map(row => ({
                              name: row.name,
                              id: row.id,
                            }))
                          }
                        >
                          {(option, { checked }) => {
                            let background = null;
                            let borderColor = 'dark-5';

                            if(checked) {
                              background = 'brand';
                              borderColor = 'brand';
                            }

                            return (
                              <Box
                                direction='row'
                                gap='small'
                                justify='between'
                                align='center'
                              >
                                <Box
                                  width={'20px'}
                                  height={'20px'}
                                  background={background}
                                  round='xxsmall'
                                  border={{
                                    color: borderColor
                                  }}
                                />

                                <Text color='dark-2' size='small'>{option.name}</Text>
                              </Box>
                            )
                          }}
                        </CheckBoxGroup>
                      </Box>
                    </Box>

                    <Box
                      border={{ color: 'light-3', side: 'bottom' }}
                      margin={{ top: 'medium', bottom: 'medium' }}
                    />


                    {/* Notificar por SMS */}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                      gap='small'
                    >
                      <Box
                        direction={ size === 'small' ? 'column' : 'row' }
                        align='stretch'
                      >
                        <Box
                          width={ size === 'small' ? '100vw' : '70vw '}
                          gap='xxsmall'
                        >
                          <TitleField
                            text='Notificar os participantes por WhatsApp?'
                          />

                          <DescriptionField
                            text='A notificação é enviada até 30 minutos antes do horário de início.'
                          />
                        </Box>

                        <Box
                          width={ size === 'small' ? '100vw' : '40vw' }
                        >
                          <Select
                            options={selectYesNoOptions}
                            value={notify}
                            labelKey='name'
                            valueKey={{ key: 'id', reduce: true }}
                            disabled={ participant?.length === 0 }
                            title={ participant?.length === 0 ? 'Selecione os participantes' : null }
                            onChange={({ value: nextValue }) =>
                              changeValues({ fieldName: 'notify', value: nextValue }, 'formChangeValues')
                            }
                          />
                        </Box>
                      </Box>

                      {notify && (
                        <Box
                          round={ size === 'small' ? 'xxsmall' : 'xsmall' }
                          border={{ color: 'light-4' }}
                        >
                          {participant.map((userId) => {
                            const user = usersOptions.find(a => a.id === userId);

                            return (
                              <Box
                                key={user.id}
                                direction='row'
                                justify='between'
                                pad={{
                                  vertical: size === 'small' ? 'medium' : 'small',
                                  horizontal: size === 'small' ? 'medium' : 'small',
                                }}
                                border={{
                                  color: 'light-4',
                                  side: 'bottom',
                                }}
                              >
                                <Text size='small' color='dark-2'>{user.name}</Text>

                                {user.phone ?
                                  <Tag
                                    text={user.phone}
                                    textSize='xsmall'
                                    background='#d5ffe8'
                                    textColor='neutral-1'
                                  />
                                :
                                  <Tag
                                    text='sem celular'
                                    textSize='xsmall'
                                    background='#fff4dd'
                                    textColor='status-warning'
                                  />
                                }
                              </Box>
                            )
                          })}
                        </Box>
                      )}
                    </Box>


                    <Box
                      border={{ color: 'light-3', side: 'bottom' }}
                      margin={{ top: 'medium', bottom: 'medium' }}
                    />

                    {/* Integração Google Agenda */}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                      gap='small'
                    >
                      <Box
                        direction={ size === 'small' ? 'column' : 'row' }
                        align='stretch'
                      >
                        <Box
                          width={ size === 'small' ? '100vw' : '70vw'}
                          gap='xxsmall'
                        >
                          <TitleField
                            text='Integrar com Google Agenda'
                          />

                          <DescriptionField
                            text='O compromisso será integrado no Google Agenda dos participantes.'
                          />
                        </Box>

                        <Box
                          width={ size === 'small' ? '100vw' : '40vw'}
                        >
                          <Select
                            options={selectYesNoOptions}
                            value={integrate}
                            labelKey='name'
                            valueKey={{ key: 'id', reduce: true }}
                            disabled={ !participant || participant.length === 0 }
                            title={ !participant || participant.length === 0 ? 'Selecione os participantes' : null }
                            onChange={({ value: nextValue }) =>
                              changeValues({ fieldName: 'integrate', value: nextValue }, 'formChangeValues')
                            }
                          />
                        </Box>
                      </Box>

                      {integrate && (
                        <Box
                          round={ size === 'small' ? 'xxsmall' : 'xsmall' }
                          border={{ color: 'light-4' }}
                        >
                          {participant.map((userId) => {
                              const integration = integrationUserOptions.find(
                                a => parseInt(a.userId) === parseInt(userId)
                              );

                              const user = usersOptions.find(a => parseInt(a.id) === parseInt(userId));

                              return (
                                <Box
                                  key={user.id}
                                  direction='row'
                                  justify='between'
                                  pad={{
                                    vertical: size === 'small' ? 'medium' : 'small',
                                    horizontal: size === 'small' ? 'medium' : 'small',
                                  }}
                                  border={{
                                    color: 'light-4',
                                    side: 'bottom',
                                  }}
                                >
                                  <Text size='small' color='dark-2'>
                                    {user.name}
                                  </Text>

                                  {integration && integration.isValid ? (
                                    <Tag
                                      text='integração configurada' textSize='xsmall'
                                      background='#d5ffe8' textColor='neutral-1'
                                    />
                                  ) : null}

                                  {integration && !integration.isValid ? (
                                    <Tag
                                      text='integração com falha' textSize='xsmall'
                                      background='#ffdddd' textColor='status-error'
                                    />
                                  ) : null}

                                  {!integration && (
                                    <Tag
                                      text='integração não configurada'
                                      textSize='xsmall'
                                      background='#fff4dd'
                                      textColor='status-warning'
                                    />
                                  )}
                                </Box>
                              )
                            })
                          }
                        </Box>
                      )}
                    </Box>


                    <Box
                      border={{ color: 'light-3', side: 'bottom' }}
                      margin={{ top: 'medium', bottom: 'medium' }}
                    />

                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Compromisso será com' />

                      <Box direction='row' gap='small'>
                        <Box width='60vw'>
                          <TextInput
                            maxLength={50}
                            value={requesterName ?? ''}
                            placeholder='Nome...'
                            onChange={event =>
                              changeValues({ fieldName: 'requesterName', value: event.target.value }, 'formChangeValues')
                            }
                          />
                        </Box>

                        <Box width='40vw'>
                          <MaskedInput
                            value={requesterPhone ?? ''}
                            placeholder='Telefone...'
                            icon={<Phone />}
                            reverse={true}
                            onChange={event =>
                              changeValues({ fieldName: 'requesterPhone', value: event.target.value }, 'formChangeValues')
                            }
                            mask={[
                              { fixed: '(' },
                              { length: 2, regexp: /\d/ },
                              { fixed: ')' },
                              { fixed: ' ' },
                              { length: 4, regexp: /\d/ },
                              { fixed: '-' },
                              { length: [4, 5], regexp: /\d/ }
                            ]}
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      border={{ color: 'light-3', side: 'bottom' }}
                      margin={{ top: 'medium', bottom: 'medium' }}
                    />

                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Descrição' />

                      <TextArea
                        resize='vertical'
                        maxLength={800}
                        value={description ?? ''}
                        onChange={event =>
                          changeValues({ fieldName: 'description', value: event.target.value }, 'formChangeValues')
                        }
                      />

                      <ErrorForm errorsList={postDataError.errors} fieldName='description' />
                    </Box>
                  </Box>


                  <Box
                    direction='row'
                    pad={{ vertical: 'medium' }}
                    justify='center'
                    align='center'
                    gap='small'
                  >
                    <Box width='xsmall'>
                      <Anchor
                        color='dark-3'
                        label='Cancelar'
                        disabled={ loadingPostForm }
                        onClick={hideFormManagerLayer}
                      />
                    </Box>

                    <Box width='small'>
                      <Button
                        color='brand'
                        primary
                        label={ !loadingPostForm ? 'Salvar': 'Salvando...' }
                        disabled={ loadingPostForm }
                        onClick={() =>
                          this.handlePost({
                            id,
                            subject,
                            date: formattedDateTime(date),
                            description,
                            hourStart,
                            hourEnd,
                            participant,
                            requesterName,
                            requesterPhone,
                            location,
                            notify,
                            integrate,
                          })
                        }
                      />
                    </Box>
                  </Box>
                </>
              ) : null}
            </Box>
          </Layer>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

const mapStateToProps = ({
  layerReducer,
  formCreateUpdateCopyGeneric,
  selectOptionsReducer,
}) => ({
  usersOptions: selectOptionsReducer.usersOptions,
  integrationUserOptions: selectOptionsReducer.integrationUserOptions,

  id: formCreateUpdateCopyGeneric.id,
  subject: formCreateUpdateCopyGeneric.subject,
  date: formCreateUpdateCopyGeneric.date,
  description: formCreateUpdateCopyGeneric.description,
  hourStart: formCreateUpdateCopyGeneric.hourStart,
  hourEnd: formCreateUpdateCopyGeneric.hourEnd,
  participant: formCreateUpdateCopyGeneric.participant,
  requesterName: formCreateUpdateCopyGeneric.requesterName,
  requesterPhone: formCreateUpdateCopyGeneric.requesterPhone,
  location: formCreateUpdateCopyGeneric.location,
  notify: formCreateUpdateCopyGeneric.notify,
  integrate: formCreateUpdateCopyGeneric.integrate,

  isEditing: formCreateUpdateCopyGeneric.isEditing,

  watingServerResponse: formCreateUpdateCopyGeneric.watingServerResponse,
  getServerPresponseError: formCreateUpdateCopyGeneric.getServerPresponseError,

  loadingPostForm: formCreateUpdateCopyGeneric.loadingPostForm,
  postDataError: formCreateUpdateCopyGeneric.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    update,
    create,
    changeValues,
    searchAppointments,
    hideFormManagerLayer,
  }, dispatch);

export const Create = connect(mapStateToProps, mapDispatchToProps)(FormCreate);

export default object => ({
  categoryId: object.categoryId,
  description: object.description,
  documents: object.documents.map(a => ({ typeId: a.typeId, number: a.number, year: a.year })),
  electorId: object.electorId,
  id: object.id,
  officeId: object.officeId,
  originId: object.originId,
  departmentId: object.departmentId,
  requestDate: object.requestDate,
  completionDate: object.completionDate,
  responsibles: object.responsibles.map(a => a.userId),
  statusId: object.statusId,
  voterName: object.voter.name,
});

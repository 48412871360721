import { getCookie, decryptAes, dateInterfere } from '../../../utils';

export default () => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return {
    id: null,
    sourceId: null,
    source: null,
    status: 'pending',
    description: null,
    taskDate: dateInterfere({
      date: new Date(),
      quantity: 2,
      interval: 'days',
    }),
    participants: [decipher?.user?.id],
    notify: false,

    isCopy: false,
    isEditing: false,
  }
};

const TYPES = ({

  // list
  loadingFormSearch: 'LOADING_SEARCH_SETTINGS_PROFILE_FORM_TOGGLE',
  searchSuccess: 'SEARCH_SETTINGS_PROFILE_SUCCESS',
  searchError: 'SEARCH_SETTINGS_PROFILE_LIST_ERROR',

  // form create
  insertItemList: 'MANAGER_SETTINGS_PROFILE_SUCCESS_INSERT_LISTING',

  // form search
  setDefaultPage: 'SET_SEARCH_SETTINGS_PROFILE_DEFAULT_PAGE',
});

export default TYPES;

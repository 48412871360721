import { formattedDateTime } from '../../../utils';

export default object => {
  const {
    electorId,
    name,
    individualRegistration,
    nickName,
    genre,
    sons,
    maritalStatusId,
    professionId,
    note,
    mail,
    schoolId,
    schoolName,
    professionName,
    dateOfBirth,
    status,
    allowCommunication,
    voterRegistration,
    electoralZone,
    votingSection,
    withLocation,
    voteStatusId,

    address: {
      address,
      number,
      addressAdditional,
      districtId,
      cityId,
      stateId,
      zipCode,
      districtName,
      cityName,
      stateName,
    } = {},

    relatives = [],
    telephones = [],
    groups = [],
    responsibles = [],
  } = object;

  return {
    id: electorId,
    name,
    individualRegistration,
    nickName,
    genre,
    sonsIn: sons,
    maritalStatusId,
    professionId,
    note,
    email: mail,
    address,
    addressNumber: number,
    additionalAddress: addressAdditional,
    districtId: districtId,
    cityId,
    stateId,
    zipcode: zipCode,
    schoolId,
    district: districtName,
    city: cityName,
    state: stateName,
    school: schoolName,
    profession: professionName,
    voterRegistration,
    electoralZone,
    votingSection,
    voteStatusId,
    dateOfBirth: formattedDateTime(dateOfBirth),

    status,
    allowCommunication,
    withLocation,

    groupsId: groups.map(a => a.groupId),

    telephone: telephones.map(a => ({
      number: a.number,
      type: a.typeId?.toString(),
      operator: a.operatorId?.toString(),
    })),

    relatives: relatives.map(a => ({
      kinshipName: a.kinShipName,
      electorName: a.connectionElectorName,

      kinship: a.kinShipId,
      electorId: a.connectionElectorId,
    })),

    responsible: responsibles.map(a => ({
      responsibleId: a.responsibleId,
      responsibleType: a.responsibleType,
      name: a.name,
    })),
  }
};

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, TextInput, Select, MaskedInput, CheckBox, Anchor } from 'grommet';
import { Trash, AddCircle } from 'grommet-icons';

import { TitleField } from '../../../../components/form';
import { ErrorForm } from '../../../../components';

import selectPhoneOperatorOptions from '../../../../constants/select-phone-operator-options';
import selectPhoneTypeOptions from '../../../../constants/select-phone-type-options';

import { changeField, changeFieldsTelephone, addTelephone, removeTelephone } from '../../actions/form-create';

function Contacts(props) {
  const {
    email,
    telephone,
    allowCommunication,
    postDataError,

    changeField,
    changeFieldsTelephone,
    addTelephone,
    removeTelephone,
  } = props;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box margin={{ bottom: 'medium' }}>
            <Text size='large'>
              Dados para contato
            </Text>
          </Box>

          <Box
            direction={size !== 'small' && size !== 'medium' ? 'row' : 'column-reverse'}
          >
            {/* coluna 1 - contacts */}
            <Box
              margin={{ right: 'large' }}
              width={size !== 'small' && size !== 'medium' ? '50vw' : '100vw'}
            >
              <Box margin={{ vertical: 'small' }}>
                <TitleField text={'E-mail'} />

                <TextInput
                  maxLength={50}
                  value={email ?? ''}
                  onChange={event => changeField({ fieldName: 'email', value: event.target.value })}
                />

                <ErrorForm errorsList={postDataError.errors} fieldName='email' />
              </Box>

              <Box
                margin={{ top: 'xsmall', bottom: 'small' }}
                direction='row'
                justify='between'
                align='center'
              >
                <Text size='medium' color='dark-2'>
                  Telefone(s) de contato
                </Text>

                <Anchor
                  icon={<AddCircle size='medium' />}
                  title={telephone.length >= 2 ? 'Só é possível adicionar 2 (dois) telefones' : 'Adicionar telefone'}
                  disabled={telephone.length >= 2}
                  onClick={() => addTelephone({
                      number: null,
                      type: null,
                      operator: null,
                    })
                  }
                />
              </Box>

              {telephone && telephone.map((item, index) => {
                return (
                  <Box
                    key={index}
                    margin={{
                      vertical: 'small',
                    }}
                  >
                    <Box
                      direction='row'
                      gap='small'
                    >
                      <Box width='30vw'>
                        <TitleField text='Telefone' />

                        <MaskedInput
                          mask={[
                            { fixed: '(' },
                            { length: 2, regexp: /\d/ },
                            { fixed: ')' },
                            { fixed: ' ' },
                            { length: 4, regexp: /\d/ },
                            { fixed: '-' },
                            { length: [4, 5], regexp: /\d/ }
                          ]}
                          value={item.number ?? ''}
                          placeholder='00 0000 0000'
                          onChange={event => changeFieldsTelephone({ value: event.target.value, item: 'number', index })}
                        />
                      </Box>

                      <Box width='30vw'>
                        <TitleField text='Tipo' />

                        <Select
                          options={selectPhoneTypeOptions}
                          value={item.type ?? ''}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          onChange={({ value: nextValue }) =>
                            changeFieldsTelephone({ value: nextValue, item: 'type', index })
                          }
                        />
                      </Box>

                      <Box width='30vw'>
                        <TitleField text='Operadora' />

                        <Select
                          options={selectPhoneOperatorOptions}
                          value={item.operator ?? ''}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          onChange={({ value: nextValue }) =>
                            changeFieldsTelephone({ value: nextValue, item: 'operator', index })
                          }
                        />
                      </Box>

                      <Anchor
                        icon={<Trash />}
                        color='status-error'
                        title='Remover'
                        onClick={() =>
                          removeTelephone(item)
                        }
                      />
                    </Box>
                  </Box>
                )
              })}


            </Box>

            {/* coluna 2 - contacts */}
            <Box
              margin={{
                bottom: 'small',
                left: size !== 'small' && size !== 'medium' ? 'large' : 'none'
              }}
              width={
                size !== 'small' &&
                size !== 'medium' ? '40vw' : '100vw'
              }
            >
              <Box
                direction='row'
                align='center'
                gap='small'
              >
                <CheckBox
                  checked={allowCommunication}
                  onChange={
                    (event) => changeField({ fieldName: 'allowCommunication', value: event.target.checked })
                  }
                />

                <Text
                  color='dark-1'
                  size='small'
                >
                  Eleitor autoriza receber comunicações por SMS, email ou WhatsApp.
                </Text>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ votersReducer: { formManager } }) => ({
  email: formManager.email,
  telephone: formManager.telephone,
  allowCommunication: formManager.allowCommunication,
  postDataError: formManager.postDataError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    changeField,
    changeFieldsTelephone,
    addTelephone,
    removeTelephone,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);

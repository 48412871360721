import TYPES from './types';
import { list } from '../../../api/common';

const loadingSearchToggle = () => ({
  type: TYPES.loadingFormSearch,
});

export const search = params => {
  return dispatch => {
    dispatch(loadingSearchToggle());
    list(params, 'grupo.php')
      .then(response =>
        dispatch({
          type: TYPES.searchSuccess,
          payload: response,
        })
      )
      .then(() => dispatch(loadingSearchToggle()))
      .catch(() => dispatch({ type: TYPES.searchError }))
  }
};

export const setDefaultPageRules = () => ({
  type: TYPES.setDefaultPage,
});

import TYPES from './types';
import { summaryManifestation } from '../../../api/manifestation';
import { getProfile } from '../../../api/client';

export const getSummary = () => {
  return async dispatch => {
    try {
      const [summary, profile] = await Promise.all([
        summaryManifestation(), getProfile()
      ]);

      return dispatch({
        type: TYPES.summaryData,
        payload: {
          summary: summary.data,
          profile: profile.data,
        }
      })
    }
    catch (error) {
      return dispatch({ type: TYPES.summaryRequestError })
    }
  }
}

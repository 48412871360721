import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Details } from '../components/details/details';
import { Search } from '../components/form-search/form-search';
import { Delete } from '../components/form-delete/form-delete';
import { ChangeStatus } from '../components/form-change-status/form-change-status';
import { Comment } from '../components/form-comment/form-comment';
import { Reminder } from '../components/form-reminder/form-reminder';
import { Create } from '../components/form-manager/form-manager';
import { PrepareDoc } from '../components/form-prepare-doc/form-prepare-doc';
import { SearchResult } from '../components/search-result/search-result';
import { Communication } from '../components/communication/communication';
import { CreateArchive } from '../components/form-archive/form-archive';

import Header from '../../../components/header/header';

import { searchLegislativeProcesses, setDefaultPageRules } from '../actions/form-search';
import {
  getUsersOptions,
  getDocumentTypeOptions,
  getStatusLegislativeProcess,
  getDepartments,
} from '../../../actions/select-options';

import paginationDefault from '../../../constants/pagination';

class LegislativeProcess extends Component {
  componentDidMount() {
    const {
      getUsersOptions,
      getDocumentTypeOptions,
      getStatusLegislativeProcess,
      getDepartments,

      searchLegislativeProcesses,
      setDefaultPageRules,

      type,
      status,
      areaInterest,
      year,
      number,
      menu,
      region,
    } = this.props;

    getUsersOptions();
    getDocumentTypeOptions();
    getStatusLegislativeProcess();
    getDepartments();

    setDefaultPageRules();

    searchLegislativeProcesses({
      type,
      status,
      areaInterest,
      year,
      number,
      menu,
      region,
      page: paginationDefault.page,
      limit: paginationDefault.limit,
    });
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <SearchResult />

        <Search />
        <Delete />
        <ChangeStatus />
        <Comment />
        <Reminder />
        <Create />
        <PrepareDoc />
        <Details />
        <CreateArchive />
        <Communication />
      </>
    )
  }
}

const mapStateToProps = ({ legislativeProcessesReducer: { formSearch }, authReducer: { authenticated } }) => ({
  userLogged: authenticated,

  type: formSearch.type,
  status: formSearch.status,
  areaInterest: formSearch.areaInterest,
  year: formSearch.year,
  number: formSearch.number,
  menu: formSearch.menu,
  region: formSearch.region,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    getUsersOptions,
    getDocumentTypeOptions,
    getStatusLegislativeProcess,
    getDepartments,
    searchLegislativeProcesses,
    setDefaultPageRules,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(LegislativeProcess);

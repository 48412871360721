const TYPES = ({

  // list
  loadingFormSearch: 'LOADING_SEARCH_SETTINGS_DEPARTMENT_FORM_TOGGLE',
  searchSuccess: 'SEARCH_SETTINGS_DEPARTMENT_SUCCESS',
  searchError: 'SEARCH_SETTINGS_DEPARTMENT_LIST_ERROR',

  // form create
  updateList: 'UPDATE_LIST_SETTINGS_DEPARTMENT',
  insertList: 'INSERT_LIST_SETTINGS_DEPARTMENT',
  deleteList: 'DELETE_LIST_SETTINGS_DEPARTMENT',

  // form search
  setDefaultPage: 'SET_SEARCH_SETTINGS_DEPARTMENT_DEFAULT_PAGE',
});

export default TYPES;

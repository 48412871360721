const TYPES = ({
  // details
  detailsData: 'GET_CUSTOMER_DETAILS',
  communicationBalanceData: 'GET_COMMUNICATION_BALANCE_DETAILS',
  detailsError: 'GET_CUSTOMER_DETAILS_ERROR',

  // list invoices
  loadingFormSearch: 'LOADING_SEARCH_INVOICES_FORM_TOGGLE',
  searchSuccess: 'SEARCH_INVOICES_SUCCESS',
  searchError: 'SEARCH_INVOICES_LIST_ERROR',

  // list communication statements

  // recalculate
  recalculateResult: 'RECALCULATE_BUY_COMMUNICATION',
});

export default TYPES;

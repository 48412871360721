import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, Select, Anchor, TextInput, MaskedInput } from 'grommet';
import { Spinning } from 'grommet-controls';

import { TitleField } from '../../../../components/form';

import { searchSchoolOptions } from '../../actions/select-options';
import { changeField } from '../../actions/form-create';

import selectVoterStatusOptions from '../../../../constants/select-voter-status-options';

class School extends Component {
  onSearchSchools = text => {
    const { searchSchoolOptions } = this.props;

    if (text.length < 2) return;
    searchSchoolOptions({ active: true, name: text })
  };

  render() {
    const {
      schoolOptions,
      schoolOptionsLoad,

      voterRegistration,
      electoralZone,
      votingSection,
      schoolId,
      school,
      voteStatusId,

      changeField,
    } = this.props;

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <>
            <Box margin={{ bottom: 'medium' }}>
              <Text size='large'>
                Dados votação
              </Text>
            </Box>

            <Box
              direction={size !== 'small' && size !== 'medium' ? 'row' : 'column-reverse'}
            >
              {/* coluna 1 - settings */}
              <Box
                margin={{ right: 'large' }}
                width={size !== 'small' && size !== 'medium' ? '50vw' : '100vw'}
              >
                {/* Vota? */}
                <Box margin={{ vertical: 'small' }}>
                  <TitleField text={'Status do voto desse eleitor'} />

                  <Select
                    options={selectVoterStatusOptions}
                    value={voteStatusId}
                    labelKey='name'
                    valueKey={{ key: 'id', reduce: true }}
                    onChange={({ value: nextValue }) => changeField({ fieldName: 'voteStatusId', value: nextValue })}
                    emptySearchMessage='Selecione uma opção'
                  />
                </Box>

                {/* título/zona/seção */}
                <Box
                  direction={ size === 'small' ? 'column' : 'row' }
                  gap='small'
                >
                  <Box
                    margin={{ vertical: 'small' }}
                    width={ size === 'small' ? '100vw' : '50vw' }
                  >
                    <TitleField text='Título de eleitor' />

                    <MaskedInput
                      mask={[
                        { length: 4, regexp: /\d/ },
                        { fixed: ' ' },
                        { length: 4, regexp: /\d/ },
                        { fixed: ' ' },
                        { length: 4, regexp: /\d/ },
                      ]}
                      value={voterRegistration ?? ''}
                      placeholder='0000 0000 0000'
                      onChange={event => changeField({ fieldName: 'voterRegistration', value: event.target.value })}
                    />
                  </Box>

                  <Box
                    margin={{ vertical: 'small' }}
                    width={ size === 'small' ? '100vw' : '25vw' }
                  >
                    <TitleField text={'Zona eleitoral'} />

                    <TextInput
                      type='number'
                      min={0}
                      value={electoralZone ?? ''}
                      onChange={event => changeField({ fieldName: 'electoralZone', value: event.target.value })}
                    />
                  </Box>

                  <Box
                    margin={{ vertical: 'small' }}
                    width={ size === 'small' ? '100vw' : '25vw' }
                  >
                    <TitleField text={'Seção eleitoral'} />

                    <TextInput
                      type='number'
                      min={0}
                      value={votingSection ?? ''}
                      onChange={event => changeField({ fieldName: 'votingSection', value: event.target.value })}
                    />
                  </Box>
                </Box>


                {/* Local de votação */}
                <Box margin={{ vertical: 'small' }}>
                  <Box direction='row' align='center' gap='xsmall'>
                    <TitleField text={'Local de votação'} />

                    {schoolId && (
                      <Anchor
                        size='small'
                        label='limpar'
                        onClick={() => changeField({ fieldName: 'schoolId', value: null })}
                      />
                    )}
                  </Box>

                  <Select
                    searchPlaceholder='Digite parte do nome para buscar...'
                    emptySearchMessage='Digite para buscar...'
                    options={
                      schoolOptions.length ? schoolOptions : [
                        { id: schoolId, name: school }
                      ]
                    }
                    value={schoolId ?? ''}
                    labelKey='name'
                    valueKey={{ key: 'id', reduce: true }}
                    onChange={({ value: nextValue }) => changeField({ fieldName: 'schoolId', value: nextValue })}
                    onSearch={text => this.onSearchSchools(text)}
                    icon={
                      schoolOptionsLoad ? (
                        <Spinning size='medium' color='brand' kind='pulse' />
                      ) : (
                        true
                      )
                    }
                  >
                    {({ id, name, city, state }) => (
                        <Box pad={{ horizontal: 'small', vertical: 'small' }}>
                          <Text size='small' color='dark-1'>{name}</Text>
                          {city && state && (<Text size='xsmall' color='dark-5'>{city} - {state}</Text>)}
                        </Box>
                    )}
                  </Select>
                </Box>
              </Box>

              {/* coluna 2 - settings */}
              <Box
                margin={{
                  bottom: 'small',
                  left: size !== 'small' && size !== 'medium' ? 'large' : 'none'
                }}
                width={
                  size !== 'small' &&
                  size !== 'medium' ? '40vw' : '100vw'
                }
              />
            </Box>
          </>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

const mapStateToProps = ({ votersReducer: { formManager, selectOptions } }) => ({
  schoolOptions: selectOptions.schoolOptions,
  schoolOptionsLoad: selectOptions.schoolOptionsLoad,

  voterRegistration: formManager.voterRegistration,
  electoralZone: formManager.electoralZone,
  votingSection: formManager.votingSection,
  schoolId: formManager.schoolId,
  school: formManager.school,
  voteStatusId: formManager.voteStatusId,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    searchSchoolOptions,
    changeField,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(School);

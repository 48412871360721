import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, Button } from 'grommet';
import { Trash, FormFolder, Directions, AddCircle } from 'grommet-icons';

import { Placeholder, ErrorsSearches } from '../../../../components';
import { toggleFormDeleteLayer } from '../../actions/form-delete';
import { showFormManagerLayer } from '../../actions/form-manager';

const SearchResult = (props) => {
  const {
    loadingFormSearch,
    dataRows,
    getListError,

    toggleFormDeleteLayer,
    showFormManagerLayer,
  } = props;

  if(loadingFormSearch && !getListError) {
    return (
      <Placeholder
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    );
  }

  if(getListError) {
    return <ErrorsSearches />;
  }

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '100px' }}
          >
            <Box
              pad='small'
              width='xlarge'
              gap='medium'
            >
              {/* Cabeçalho */}
              <Box
                direction='row'
                gap='xsmall'
                align='baseline'
              >
                <Text size='large' color='dark-5'>Configure a secretária dos</Text>
                <Text size='xlarge' color='dark-1'>Atendimentos e Docs. Proposituras, Adm.</Text>
              </Box>

              {/* Conteúdo */}
              <Box
                gap='medium'
                direction={ size === 'small' || size === 'medium' ? 'column' : 'row' }
                justify='between'
              >

                {/* Primeira coluna */}
                <Box
                  width={
                    size === 'small' || size === 'medium' ? 'xlarge' : 'xlarge'
                  }
                  gap='medium'
                >
                  <Text color='dark-2' size='small'>
                    Defina as secretárias que serão utilizadas nos atendimentos e
                    também no cadastro de ofícios, moções, etc.
                  </Text>

                  <Box
                    direction='row'
                    gap={ size === 'small' ? 'large' : 'medium' }
                    margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                  >
                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      align='baseline'
                      width='50vw'
                    >
                      <AddCircle size='medium' color='brand' />

                      <Text size='small' color='dark-2' weight='bold'>
                        Adicione e edite
                      </Text>

                      <Text size='small' color='dark-2'>
                        Adicione e altere quando precisar. É fácil e rápido!
                      </Text>
                    </Box>

                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      align='baseline'
                      width='50vw'
                    >
                      <FormFolder size='medium' color='brand' />

                      <Text size='small' color='dark-2' weight='bold'>
                        Organização e classificação
                      </Text>

                      <Text size='small' color='dark-2'>
                        Personalizar as secretárias permite
                        organizar melhor os atendimentos e documentos.
                      </Text>
                    </Box>
                  </Box>

                  <Box
                    direction='row'
                    gap={ size === 'small' ? 'large' : 'medium' }
                    margin={{ bottom: size === 'small' ? 'large' : 'medium' }}
                  >
                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      align='baseline'
                      width='50vw'
                    >
                      <Directions size='medium' color='brand' />

                      <Text size='small' color='dark-2' weight='bold'>
                        Direcionamento eficiente
                      </Text>

                      <Text size='small' color='dark-2'>
                        Definindo a secretária no atendimento,
                        fica mais fácil direcionar para a secretária responsável.
                      </Text>
                    </Box>

                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      align='baseline'
                      width='50vw'
                    />
                  </Box>

                  <Button
                    primary
                    label='Adicionar secretária'
                    color='neutral-1'
                    onClick={() => showFormManagerLayer() }
                  />
                </Box>

                {/* Separador coluna */}
                <Box
                  width={ size === 'large' || size === 'xlarge' ? 'xsmall' : 'xxsmall'}
                />

                {/* Segunda coluna */}
                <Box
                  gap='small'
                  width={ size === 'small' || size === 'medium' ? 'xlarge' : 'xlarge' }
                >
                  {dataRows.some(a => a.clientId !== null) ? (
                    <Box
                      round='xsmall'
                      border={{ color: 'light-4' }}
                      background='white'
                    >
                      {dataRows.filter(a => a.clientId !== null).map(row => (
                          <Box
                            key={row.id}
                            align='center'
                            direction='row'
                            pad={{
                              horizontal: size === 'small' ? 'large' : 'medium',
                              vertical: size === 'small' ? 'medium' : 'xsmall'
                            }}
                            border={{ color: 'light-3', side: 'bottom' }}
                            justify='between'
                            gap='small'
                          >
                            <Box width='medium'>
                              <Text color='dark-2' size='small'>{row.name}</Text>

                              <Text
                                color='dark-4'
                                size='xsmall'
                              >
                                {row.serviceTotal > 0 || row.documentTotal > 0 ?
                                    <>Em uso</>
                                  : <>Não é utilizada ainda</>
                                }
                              </Text>
                            </Box>

                            <Box
                              direction='row'
                              align='center'
                              gap='small'
                            >
                              <Button
                                size='small'
                                label='Editar'
                                color='light-5'
                                onClick={() => { showFormManagerLayer(row) }}
                              />

                              <Button
                                title={
                                  row.serviceTotal > 0 || row.documentTotal > 0 ?
                                    'Remova os atendimentos e documentos vinculados antes de excluir' :
                                    'Excluir'
                                }
                                disabled={row.serviceTotal > 0 || row.documentTotal > 0}
                                icon={<Trash color='status-error' size='medium' />}
                                onClick={() => toggleFormDeleteLayer(row) }
                              />
                            </Box>
                          </Box>
                        )
                      )}
                    </Box>
                  ) : null}

                  <Box
                    round='xsmall'
                    border={{ color: 'light-4' }}
                    background='white'
                  >
                    <Box
                      pad={{
                        vertical: size === 'small' ? 'medium' : 'xsmall',
                        horizontal: size === 'small' ? 'large' : 'medium',
                      }}
                    >
                      <Text
                        color='status-warning'
                        size='small'
                      >
                        Padrão do sistema não podem ser editada ou excluida.
                      </Text>
                    </Box>

                    {dataRows.filter(({ clientId }) => clientId === null).map(({ id, name }) => (
                      <Box
                        key={id}
                        pad={{
                          vertical: size === 'small' ? 'medium' : 'xsmall',
                          horizontal: size === 'small' ? 'large' : 'medium',
                        }}
                        border={{
                          color: 'light-3',
                          side: 'bottom',
                        }}
                      >
                        <Text color='dark-2' size='xsmall'>{name}</Text>
                      </Box>
                      )
                    )}
                  </Box>

                </Box>
              </Box>
            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ settingsDepartmentReducer: { searchResult } }) => ({
  loadingFormSearch: searchResult.loadingFormSearch,
  dataRows: searchResult.dataRows,
  getListError: searchResult.getListError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    toggleFormDeleteLayer,
    showFormManagerLayer,
  }, dispath)
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchResult)
);
